@media (min-width:768px) and (max-width:1199px) {
    .main-banner .inner {
        padding: 0 30px;
    }

    .main-banner h2 {
        font-size: 38px;
        margin: 0 0 8px;
    }

    .main-banner h4 {
        font-size: 18px;
        margin: 0 0 25px;
    }

    .app-btn-set a {
        padding: 0 22px 0 50px;
        margin-right: 10px;
        font-size: 16px;
        height: 50px;
    }

    .app-btn-set a i {
        left: 18px;
        font-size: 29px;
        width: 22px;
    }

    .form-group label {
        height: 42px;
        font-size: 14px;


    }

    .form-group {
        margin: 0 0 12px;
    }

    .inner-wrap .body {
        padding: 50px 20px 20px;
        border-radius: 45px 45px 0 0;
    }

    .input-phone .input-group-btn .btn {
        font-size: 14px;
        height: 42px;
    }

    .form-group .form-control {
        height: 42px;
        font-size: 14px;
        padding: 0 15px;
    }

    .code-field button {
        padding: 0 15px;
        height: 38px;
        font-size: 13px;
    }

    html .btn {
        font-size: 15px;
    }

    p {
        font-size: 14px;
    }

    .social-btn a {
        line-height: 40px;
        font-size: 14px;
    }

    .social-btn a i {
        font-size: 16px;
    }

    .otp-field input {
        width: 50px;
        height: 50px;
    }

    .form-group .date-field .icon {
        right: 18px;
        height: 42px;
        width: 20px;
    }

    html .ui-selectmenu-button.ui-button {
        font-size: 15px;
        padding: 0 50px 0 20px;
        line-height: 40px;
    }

    .footer-menu {
        font-size: 10px;
    }

    .contest-list .box .inner_row .inner_item {
        padding: 0 5px;
    }

    .inner-wrap .header h2 {
        font-size: 18px;
        max-width: 70%;
        text-align: center;
    }

    .cvv-icon .icon {
        top: 33px;
    }

    .search-bank .form-control {
        padding-left: 45px !important;
    }

}

@media (max-width:767px) {

    /* .sign_in_country_code_popup_button {
        width: 95px;
        margin-left:0px;
    } */
    .main-banner {
        display: none;
    }

    .inner-wrap .header {
        padding-top: 15px;
    }

    .inner-wrap .header .logo {
        max-width: 110px;
    }

    /* .inner-wrap {
        padding-top: 75px;
    } */

    .inner-wrap .body {
        padding: 35px 15px 15px;
        border-radius: 30px 30px 0 0;
    }

    .app-btn-set a {
        padding: 0 22px 0 50px;
        margin-right: 10px;
        font-size: 16px;
        height: 50px;
    }

    .app-btn-set a i {
        left: 18px;
        font-size: 29px;
        width: 22px;
    }

    .form-group label {
        font-size: 13px;
        height: 35px;
    }

    .form-group {
        margin: 0 0 12px;
    }

    .input-phone .input-group-btn .btn {
        font-size: 14px;
        height: 42px;
    }

    .form-group .form-control {
        height: 42px;
        font-size: 14px;
        padding: 0 15px;
    }

    .code-field button {
        padding: 0 15px;
        height: 38px;
        font-size: 13px;
    }

    html .btn {
        font-size: 15px;
        line-height: 42px;
    }

    p {
        font-size: 14px;
    }

    .social-btn a {
        line-height: 40px;
        font-size: 14px;
    }

    .social-btn a i {
        font-size: 16px;
    }

    .otp-field input {
        width: 50px;
        height: 50px;
    }

    .form-group .date-field .icon {
        right: 18px;
        height: 42px;
        width: 20px;
    }

    html .ui-selectmenu-button.ui-button {
        font-size: 15px;
        padding: 0 50px 0 20px;
        line-height: 40px;
    }

    html .ui-selectmenu-text {
        margin-right: 0;
    }

    html .ui-selectmenu-button.ui-button:after {
        margin: auto 20px;
    }

    .footer-menu {
        font-size: 10px;
    }

    .header .wallet-btn {
        margin-top: 10px;
    }

    .with-footer .inner-wrap .body {
        border-radius: 22px;
    }

    .text-header .inner-wrap {
        padding-top: 70px;
    }

    .games_items.games_big_items .box .img {
        height: 130px;
    }

    .contest-list .box .inner_row .inner_item {
        padding: 0 7px;
    }

    .contest-list.contest-tournament-list .box .inner_row .inner_item.inner_item_big {
        width: 60%;
    }

    .contest-tournament-list .contest_foot .inner a {
        font-size: 12px;
        line-height: 20px;
    }

    .contest-tournament-list.contest-list .box h3 {
        font-size: 13px;
    }

    .list-group.main-tab {
        margin: 0 0 10px;
    }

    .contest_toggle_btn {
        font-size: 15px;
        padding: 0px 0px;
    }

    .contest_toggle_btn span {
        font-size: 10px;
    }

    .friendship_items .box {
        font-size: 12px;
    }

    .list-group.main-tab .list-group-item {
        font-size: 14px;
    }

    .round-chart .grp .label img {
        width: 12px;
        margin-left: 2px;
    }

    .player_icons {
        margin-bottom: 10px;
    }

    .player_list .item .inner.inner_big {
        /* padding-left: 30px; */
        min-height: 30px;
        padding-top: 0;
    }

    .player_list .item .inner.inner_big .icon {
        width: 26px;
        top: 2px;
    }

    ul.cust_list {
        font-size: 13px;
    }

    .inner-wrap .header h2 {
        font-size: 18px;
        max-width: 70%;
        text-align: center;
    }

    .wrapper>.row>[class*="col"]:first-child {
        flex-basis: 100%;
        max-width: 100%;
        min-width: 0;
    }

    .current_balance_box {
        padding: 12px 25px;
    }

    .cmt .item+.item {
        margin-top: 12px;
    }

    .cmt {
        margin-top: 20px;
    }

    .cvv-icon .icon {
        top: 33px;
    }

    .search-bank .form-control {
        padding-left: 45px !important;
    }

    .pan-btn a {
        padding: 12px 20px;
        font-size: 14px;
    }

    .upload-pan {
        padding: 15px 20px !important;
    }

    .bank-details h4 {
        font-size: 17px;
    }

    .bank-details p {
        font-size: 14px;
    }

    .bank-details {
        margin: 20px auto 20px;
        padding: 12px;
    }

    .pan-note p {
        font-size: 14px;
    }

    .proceed-link h5 {
        font-size: 16px;
    }

    .proceed-link {
        margin: 15px 0;
    }

    .pay-mth-icon {
        margin-bottom: 30px;
    }
}

/* Edit */
@media (max-width: 475px) and (min-width: 0) {
    /* .sign_in_country_code_popup_button {
        width: 85px;
    } */

    .tournament_items .owl-theme .owl-nav {
        display: none;
    }

    .avatars_main .owl-theme .owl-nav {
        display: none;
    }

    .reward_cat_main .owl-theme .owl-nav {
        display: none;
    }

    .woe_div_3_col .owl-theme .owl-nav {
        display: none;
    }

    .image img {
        width: 40px;
        height: 40px;
    }

    .f14 {
        font-size: 14px;
    }

    .image-size {
        width: 26px !important;
        height: 26px !important;
    }

    .scrim-squad-registration-Caption {
        font-size: 12px;
        padding: 5px 5px;
    }

    .scrim-squad-registration-input {
        font-size: 13px;
    }

    .scrim-balance-button {
        font-size: 14px;
    }

    .balance_img {
        width: 19px;
        height: 19px;
    }

    .rd-btn {
        font-size: 12px;
    }

    .rd-btn1 {
        font-size: 13px;
    }

    .renew_layer {
        padding-right: 10px;
    }

    .subImage {
        padding: 0 12px;
    }

    .renew_font {
        font-size: 12px;
    }

    .ps_buttonGroup {
        font-size: 13px;
    }

    .ps_info_detail {
        font-size: 12px;
    }

    .ps_summary p {
        font-size: 15px;
    }

    .ps_popup_p {
        padding: 5px 0;
    }

    .ps_profile_img {
        position: absolute;
        width: 48px;
        height: 42px;
        right: 10px;

    }

    .ps_avatar_icon_img {
        width: 180px;
        max-width: 180px;
    }

    .ps_info_logo img {
        width: 35px;
        height: 28px;
    }
    .r-40{
        right :20%;
      }
      .profile_followings_data{
        font-size: 16px;
      }
      .profile_followers_data{
        font-size: 16px;
      }
      .r-10{
        right: 0;
      }
      .callback-text{
        font-size: 10px !important;
      }
      .f-20{
        font-size: 20px;
      }
      .font-1{
        font-size: 1.2rem;
      }
      .font-09{
        font-size: 0.8rem;
      }
      .font-01{
        font-size: 1rem;
      }
      .auction-team{
        max-height: 15rem;
      }
      .mgT-8{
        margin-top: 5rem;
      }
      .auction-box .inner_row .auction-date-box{
        font-size: 0.6rem;
      }
      .font-05{
        font-size: 0.7rem;
      }
      .he-25{
        height: 25rem;
      }
      .enter-buy-team{
        height: 40rem;
      }
      .auction-remaining .MuiSelect-select.MuiSelect-select{
        font-size: 0.8rem;
      }
      .auction-remaining .MuiInputLabel-formControl{
        font-size: 0.8rem;
      }
      .MuiTypography-body1{
        font-size: 0.8rem !important;
      }
      .MuiMenuItem-root{
        height: 45px;
      }
      .bid-remaining span{
        font-size: 0.8rem;
      }
      .match-text{
        font-size: 11px;
      }
      .bid-box-inner{
        padding: 10px;
      }
      .font-10{
        font-size: 12px;
      }
      .league_tab .list-group-item{
        font-size: 1rem;
      }
      .league_public_tab .list-group-item{
        font-size: 1rem;
      }
      .bid-box{
        height: 16rem;
      }
      .icon-24{
        width: 20px;
        height: 20px;
        margin-top: -5px;
      }
      .font-18{
        font-size: 15px;
      }
}



@media (max-width: 414px) {
    html[dir="rtl"] .searchCollege button {
        right: 72%;
        /* left: 0 !important; */
    }
    .reward_first_main .redeem_popup{
        right: 0;
      
      }
      
      html[dir="rtl"] .redeem_popup  .close-popup {
        left: -22rem;
      }
      html[dir="rtl"] .popup-head .close-popup {
        left: -22rem;
    }
}
@media (max-width: 375px) and (min-width: 0) {
    .ps_profile_img {
        position: absolute;
        width: 44px;
        height: 38px;
        right: 0;
    }
    .country_list_popup_body{
        min-height: 65%;
    }

    .fontSize16 {
        font-size: 18px;
    }
    .searchCollege button{
        width: 80px;
    }
    .callback-text{
        font-size: 10px !important;
      }
        
      html[dir="rtl"] .redeem_popup  .close-popup {
        left: -20rem;
      }
      html[dir="rtl"] .popup-head .close-popup {
        left: -20rem;
    }
}