/*====================================margin-top
Fonts-Family
======================================*/
:root {
  --primary: #ffc609;
  --green-primary: #09ffc4;
  --sky-primary: #43f2ff;
  --secondary: #f92c2c;
  --dark: #070b28;
  --gray: #f5f4f3;
  --light: #fff8f0;
  --color_grey: #b4b6c4;
  --dark-grey: #82878d;
  --upcoming: #109E38;
  --on-going: #082240;
  --light-white: #F8F8F8;
  --border-color: #D0D0D0;
  --base-color: #F0F2F3;
  --btn-grey: rgb(8, 34, 64, 0.3);
  --btn-grey: rgb(8, 34, 64, 0.6);
  --time-color: #FFB600;
  --bg-blue: #BADAFF;
  --upcoming-blue: #3271C6;
  --live-red: #D81F26
}

.inviteViaWhatsapp {
  text-align: center;
  display: flex;
  justify-content: center;
}

.otpemail {
  color: white;
}

.margin-bottom {
  margin-bottom: 10px;
}

.padding10 {
  padding: 10px !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-top2 {
  padding-top: 1rem;
}

.cursor {
  cursor: pointer;
}

.owl-nav i {
  font-size: 52px;
}

.customer-care.mobileNo {
  position: absolute;

  left: 0;
  margin-top: 37px;
  font-size: 14px;
  margin-left: 15px;
  color: #70717a;
  font-weight: bold;
  bottom: 7px;
}

.margin-top {
  margin-top: 1rem;
}

.margin-top3 {
  margin-top: 3rem;
}

/* scrims */
.border-left-radius {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.border-right-radius {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* .all_sub_screens_div_related_to_ads::after{
  content: "text";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.356);
  z-index: 10;
} */
.create-scrim-button {
  border: none;
  background-color: var(--secondary);
  width: 50%;
  color: var(--light);
  font-weight: 800;
  padding: 8px 0;
  cursor: pointer;
}

.br-20 {
  border-radius: 20px !important;
}

.scrim-summary-card {
  color: var(--light);
}

.create-scrim-button-selected {
  background-color: var(--dark);
  border-radius: 50px;
}

.scrim-basicDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 10px;
}

.scrim-squad-registration {
  background-color: var(--dark);
  min-height: 180px;
  border-radius: 20px;
}

.scrim-squad-registration-title {
  color: var(--white);
  padding: 3px;
}

.scrim-squad-registration-Caption {
  background-color: var(--primary);
  color: var(--dark);
  padding: 3px 10px;
  border-radius: 10px 0px 10px 0px;
  font-size: 1rem;
  height: 26px;
  display: flex;
  gap: 5px
}

html[dir="rtl"] .scrim-squad-registration-Caption {
  border-radius: 0 10px 0 10px;
}

.border-radiusSquad {
  border-radius: 0px 10px 0px 10px !important;
}

.border-radius {
  border-radius: 10px !important;
}

.align-self {
  align-self: center;
}

.scrim-squad-registration-input {
  background-color: #d9d9d90f;
  border-radius: 20px 20px 20px 20px;
  font-size: 16px;
  padding: 7px 10px;
  border: none;
  color: var(--light);
}

.space-between {
  justify-content: space-between;
}

.radio-container {
  display: flex;
  flex-direction: column;
}

label {
  display: flex;
  align-items: center;
}

input[type="radio"] {
  margin-right: 10px;
  border: 1px solid white;
  /* White border */
  border-radius: 50%;
  /* To create a circular shape */
  width: 20px;
  /* Adjust the size as needed */
  height: 20px;
  /* Adjust the size as needed */
  margin-top: 0px;
}

html[dir="rtl"] input[type="radio"] {
  margin-left: 10px;
  margin-right: 0;
}

.input-value-scrims {
  width: 80% !important;
  text-align: center;
}

.scrim-leaderboard .list-group.main-tab.leader-board .list-group-item.active {
  background-color: var(--dark) !important;
  color: var(--light) !important;
}

.scrim-leaderboard .list-group-item-action.active:hover {
  background-color: var(--dark) !important;
}

.scrim-leaderboard .main_tabs .list-group-item.active {
  background-color: var(--dark);
  border-bottom: 3px solid var(--primary);
  border-radius: unset;
}

.scrim-heading {
  margin-top: 1rem;
  border-radius: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px;
}

.scrim-body {
  border-radius: 20px;
}

.leaderboard-list {
  height: 54px;
  width: 100%;
  border-bottom: 1px solid #dfe4e9;
  position: relative;
  /* margin-bottom: 30px; */
  padding: 1rem 2rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #070b28;
}

.leaderboard-list .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-list .item .username {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  line-height: 1.2;
  /* align-items: center; */
}

.leaderboard-list .item img {
  width: 30px;
  height: 30px;
  margin-right: 0.3rem;
}

html[dir="rtl"] .leaderboard-list .item .username b {
  text-align: right;
}

.leaderboard-list .item .username b {
  font-size: 14px;
}

html[dir="rtl"] .leaderboard-list .item .username small {
  text-align: right;
}

.leaderboard-list .item .username small {
  font-size: 9px;
  font-weight: 100;
}

/* .leaderboard-list:first-child{
  background-color: #F92C2C;
  border-radius: 20px 20px 0 0 ;
  color: #ffffff;

} */
.leaderboard-list .item .rankBox {
  background-color: white !important;
  border: 1px solid #e6e6e6;
}

.leaderboard-list .item input {
  width: 35px;
  height: 28px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  text-align: center;
}

.scrim-input {
  border-radius: 50px;
  border: 2px solid #d5d7e3;
  height: 42px;
  font-size: 1rem;
  padding: 10px 15px 10px 20px;
  font-weight: 800;
  color: var(--dark);
  width: 100%;
}

/* .scrim-d{
  padding: 0 !important;
}  */

.scrim-d input {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  padding: 10px 20px;
  border: 1px solid #d3d3d3;
}

.scrim-d input:disabled {
  padding: 0;
  background-color: var(--gray);
}

html[dir="rtl"] .scrim-add-btn {
  right: 14rem !important;
}

.scrim-add-btn {
  background: var(--primary);
  color: var(--dark) !important;
  padding: 6px 18px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  position: absolute;
  right: 2px;
}

html[dir="rtl"] .scrim-remove-btn {
  right: 14.5rem !important;
}

.scrim-remove-btn {
  background-color: var(--color_red) !important;
  color: var(--dark) !important;
  padding: 6px 18px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  position: absolute;
  right: 2px;
}

.flex-basics {
  flex-basis: 50%;
}

.text-align {
  text-align: center;
}

.ridpwd {
  align-items: center;
  color: #070b28;
}

.rpBtn {
  width: 38%;
  height: 42px;
  background: #f92c2c;
  border: none;
  outline: none;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  padding: 0 25px;
  margin-top: 1.5rem;
}

.ssUpload {
  justify-content: space-between;
  margin: 25px 0;
}

.ssUpload h6 {
  font-weight: 600;
  font-size: 15px;
  color: #070b28;
}

.ssUpload button {
  padding: 5px 2rem;
  border: 1px solid #707070;
  outline: none;
  background-color: #dce3e8;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
}

.rules-input {
  height: 70vh !important;
  padding: 9px 22px !important;
}

.scrim-btns-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.scrim-heading-btns {
  border-radius: 10px !important;
  width: 100%;
  border: 3px solid var(--dark) !important;
}

.scrim-btns {
  width: 100%;
}

.scrim-btns:hover {
  background-color: var(--dark) !important;
}

.scrim-btns-selected {
  border: 3px solid var(--secondary) !important;
}

.scrim-input-label {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

.scrim-summary-rulesBox {
  height: 50vh;
  border: 2px solid #d5d7e3;
  overflow: auto;
}

.scrim-summary-rules {
  font-size: 1rem;
  padding: 20px 15px;
  color: var(--dark);
}

.scrim-rules-list {
  display: block;
  list-style-type: disc;
  margin-block-start: 0 !important;
  padding-inline-start: 15px !important;
  color: var(--dark-grey);
  font-weight: 600;
}

.scrim-rules-list li::marker {
  color: var(--secondary);
  font-size: 1.5rem;
}

.css-1s2u09g-control {
  height: 46px !important;
  border-radius: 50px;
  border: 2px solid #d5d7e3 !important;
  text-align: left;
  padding-left: 0.5rem;
}

.select__control .css-1s2u09g-control {
  align-items: left;
}

.css-319lph-ValueContainer {
  align-items: left;
}

.rdt input {
  border-radius: 50px;
  border: 2px solid #d5d7e3;
  height: 42px;
  font-size: 1rem;
  padding: 0 15px;
  font-weight: 800;
  color: var(--dark);
}

.flex {
  display: flex !important;
}

.flex-direction {
  flex-direction: column;
}

.width {
  width: 100%;
}

.input-container {
  position: relative;
  display: inline-block;
}

.input-container-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

html[dir="rtl"] .input-container-icon {
  right: 0;
  left: 80%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.SquadRegistration-scrim-input-icon {
  left: 20px;
  top: 6px;
}

.width3 {
  width: 30%;
}

html[dir="rtl"] .width3 {
  text-align: right;
}

.width7 {
  width: 70%;
}

.width8 {
  width: 80%;
}

.scrim-team-name {
  background-color: var(--dark);
  border: none;
  /* padding: 7px 10px; */
  border-radius: 20px;
  color: var(--light);
  padding-left: 8rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

html[dir="rtl"] .scrim-team-name {
  padding-right: 8rem;

}

.create-scrim-title-section {
  background: var(--secondary);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.scrim-team-name-title {
  color: var(--light) !important;
  padding: 6px 18px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 600;
  height: 35px;
  position: absolute;
  bottom: 4.1px;
}

.gap {
  gap: 1rem;
}

.gap2 {
  gap: 2rem;
}

.gap5 {
  gap: 0.5rem;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.owl-next {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.owl-next span {
  font-size: 1.5rem;
}

html[dir="rtl"] .verify {
  margin-right: 1rem !important;
  margin-left: 0 !important;
}

.verify {
  background-color: var(--secondary);
  width: fit-content;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
  color: #fff;
  box-shadow: none;
  margin-left: 1rem;
  border: 0;
  border-radius: 50px;
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
  line-height: 44px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgb(7 11 30 / 30%);
  transition: all 0.5s;
}

.header-icons {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.header-wallet {
  min-width: 40%;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  padding: 5px 10px;
}

.header-wallet img {
  width: 30%;

}

@media screen and (max-width: 700px) {
  .header-respoonsive {
    width: 100%;
  }
}

.padding {
  padding: 0 5rem !important;
}

.font-size {
  font-size: 1rem;
}

.login-mobile-btn {
  background-color: white;

  color: var(--dark);
  border: 2px solid var(--dark) !important;
  border-radius: 0px !important;
}

.bold {
  font-weight: bold;
}

.color {
  color: red;
}

.google-login {
  width: 100%;
  background-color: white !important;
  color: black !important;
}

.close-button {
  width: 24px;
  height: 24px;

  border: 5px solid var(--secondary);
  border-radius: 100%;
  background: -webkit-linear-gradient(-45deg,
      transparent 0%,
      transparent 46%,
      white 46%,
      white 56%,
      transparent 56%,
      transparent 100%),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%, white 56%, transparent 56%, transparent 100%);
  background-color: var(--secondary);

  transition: all 0.3s ease;
}

.button-section {
  display: flex;
  align-self: center;
}

.color {
  color: black;
}

.red-color {
  background-color: var(--secondary) !important;
}

.invite-button {
  /* padding: 14px 26px; */
  background-color: var(--secondary);
  color: var(--light);
  border: none;
  border-radius: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.refer-button {
  background-color: var(--primary);
  color: var(--dark);
  border: none;
  border-radius: 50px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.padding {
  padding: 10px 0;
}

.button-img {
  height: 25px;
  padding-right: 10px;
}

.college-title {
  color: var(--light);
}

.ategory_college {
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
}

* {
  outline: 0 !important;
}

body {
  font-family: "Proxima Nova";
  margin: 0;
  overflow-x: hidden;
  font-weight: normal;
  font-size: 15px;
  color: var(--dark);
  background: #070b28;
}

html a {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: var(--primary);
  text-decoration: none;
}

html a:hover {
  color: var(--dark);
  text-decoration: none;
}

svg,
img {
  max-width: 100%;
  height: auto;
  width: auto;
}

html .container {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
}

section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  z-index: 1;
}

p {
  font-size: 15px;
  color: var(--dark);
  line-height: 140%;
}

p:last-child {
  margin-bottom: 0;
}

::selection {
  color: #fff;
  background: var(--dark);
}

/*Layout Start*/
html .text-primary {
  color: var(--primary) !important;
}

html .text-secondary {
  color: var(--secondary) !important;
}

.form-group {
  margin: 0 0 15px;
  position: relative;
}

.form-group label {
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 5px;
  line-height: 120%;
  height: 42px;
}

.form-group label em {
  color: #ff0000;
  font-style: normal;
}

.form-group .form-control {
  box-shadow: none;
  background-color: var(--white);
  border: 2px solid #d5d7e3;
  color: #70717a;
  height: 48px;
  font-family: "Proxima Nova";
  font-size: 14px;
  border-radius: 50px;
  transition: all 0.5s ease;
  padding: 0 20px;
  font-weight: 600;
}

.bg-primary {
  background-color: var(--primary);
}

button .bg-primary:hover {
  background-color: var(---primary) !important;
}

.bundle-title-p {
  color: var(--white);
  font-weight: 700;
}

.bundle-title-box {
  background-color: var(--dark);
  color: var(--white);
  padding: 10px;
  border-radius: 15px;
  height: 90px;
}

.avatar-title-box {
  background-color: var(--dark);
  color: var(--white);
  padding: 10px;
  border-radius: 15px;
  height: 60px;
}

.collegiate-main {
  color: var(--dark);
  background-color: var(--white);
  border-radius: 15px;
  margin: 50px 50px 0px 50px;
}

.collegiate-main-box {
  color: var(--dark);
  margin: 50px 10px 0px 10px;
}

.collegiate-detail-main-box {
  color: var(--dark);
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collegiate-detail-head-box {
  width: 100%;
  background-color: var(--dark);
  color: var(--white);
  padding: 10px;
  border-radius: 15px;
  height: 150px;
  text-align: center;
}

.collegiate-detail-bottom-box {
  background-color: var(--primary);
  color: var(--dark);
  padding: 10px;
  border-radius: 0px 0px 15px 15px;
  border: var(--dark) 1px solid;
  height: 100px;
  width: 85%;
  text-align: center;
  margin: -40px 0px 0px 0px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
}

.collegiate-head-box {
  background-color: var(--white);
  color: var(--dark);
  padding: 10px;
  border-radius: 15px;
  height: 150px;
  text-align: center;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.collegiate-bottom-box {
  background-color: var(--primary);
  color: var(--dark);
  padding: 10px;
  border-radius: 0px 0px 15px 15px;

  height: 110px;
  width: 85%;
  text-align: center;
  margin: -40px 0px 0px 0px;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-self: center;
}

.college-table {
  border-top-right-radius: 20px;
}

.college-radius-left {
  border-top-left-radius: 20px;
}

.collegiate-botton {
  background-color: var(--secondary);
  color: var(--light);
  border-radius: 20px;
  padding: 7px 0;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  top: -20px;
  width: 220px;
}

.collegiate-botton-text {
  margin-left: 10px;
  width: 160px;
  display: -webkit-inline-box;
  line-height: 0px;
  color: var(--light);
  font-weight: 500;
}

/* Edit - Profile Collegiate */
.profile_coligate_selection_college {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile_coligate_select_college_text {
  width: 75%;
  display: flex;
  justify-content: flex-start;
  color: var(--light);
  font-size: 12px;
}

.profile_coligate_select_college_button {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid var(--light);
  border-radius: 50px;
  background-color: var(--dark);
  color: var(--light);
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.profile_coligate_submit_college_button {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--secondary);
  color: var(--light);
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}

.profile_coligate_submit_college_arrow {
  width: 15px;
  height: 15px;
}

/*  */

.bundles_second_pop {
  background-color: var(--secondary);
  color: var(--light);
  border-radius: 20px;
  padding: 7px 0;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  top: -25px;
  width: 100px;
}

.pay-btn{
  border: none;
  outline: none;
  width: 50%;
}

.form-group .form-control:focus {
  border-color: var(--primary);
}

.form-group textarea.form-control {
  height: 140px;
  padding-top: 10px;
}

.inner-wrap {
  height: 100vh;
  position: relative;
  padding-top: 60px;
  overflow: hidden;
}

.inner-wrap .body {
  padding: 20px 20px 20px;
  background: #fff;
  border-radius: 45px 45px 0 0;
  height: 100%;
  overflow: auto;
}

.inner-wrap .header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: #fff;
  padding-top: 20px;
  display: flex;
  height: 65px;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /* gap: 10px; */
}

.inner-wrap .header .logo {
  margin: 0 auto;
  position: relative;
  z-index: 5;
  padding-bottom: 20px;
  width: 40%;
}

.code-field {
  position: relative;
}

.code-field button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 20px;
  border-radius: 50px;
  border: 0;
  height: 44px;
  background: var(--primary);
  font-weight: 600;
  font-size: 14px;
  transition: all 0.5s;
  color: var(--dark);
  margin: 2px;
}

.code-field button:hover {
  background: var(--dark);
  color: #fff;
}

.input-phone input.phoneNumber {
  display: none;
}

.input-phone .input-group {
  display: block;
}

.input-phone .input-group-btn .btn {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff !important;
  border: 1px solid #d5d7e3;
  border-radius: 50px;
  justify-content: center;
  height: 48px;
  font-size: 17px;
  font-weight: 600;
  box-shadow: 0 0 0 transparent;
  color: #70717a;
  font-family: "Proxima Nova";
}

.input-phone .input-group-btn .btn.dropdown-toggle::after {
  display: none;
}

.input-phone .input-group-btn {
  width: 100%;
  display: block;
}

.input-phone .input-group-btn .btn .caret {
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-top: 0;
  border-right: 2px solid;
  border-left: 0;
  transform: rotate(45deg);
  margin-top: -6px;
  margin-right: 3px;
}

.form-group.code-apply-checkbox {
  text-align: center;
}

.code-apply-checkbox label {
  cursor: pointer;
}

.code-apply-checkbox label span {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  border: 1px solid #d5d7e3;
  border-radius: 50%;
  position: relative;
  margin: 0px 7px 0 0;
  transition: all 0.3s;
}

.code-apply-checkbox label input:checked~span {
  border-color: var(--primary);
  background: var(--primary);
}

.code-apply-checkbox label input {
  display: none;
}

.code-apply-checkbox label span:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  transition: all 0.3s;
  opacity: 0;
  height: 5px;
  border-left: 2px solid var(--dark);
  border-bottom: 2px solid var(--dark);
  transform: rotate(-45deg);
  margin: 3px;
}

.code-apply-checkbox label input:checked~span:after {
  opacity: 1;
}

html .btn {
  border: 0;
  border-radius: 50px;
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
  line-height: 44px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 30, 0.3);
  transition: all 0.5s;
}

html .btn.btn-dark {
  background: var(--dark);
  color: #fff;
}

html .btn.btn-dark:hover {
  background: #070b28ba;
  color: #fff;
}

html .btn.main-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.create-scrim-btn {
  width: 90%;
  margin-top: 1rem;
}

html .btn.main-btn img {
  max-width: 18px;
}

html[dir="rtl"] .btn.main-btn img {
  transform: rotate(180deg);
}

.social-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.social-btn a {
  display: flex;
  background: #1c5da7;
  width: 48%;
  border-radius: 50px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  transition: all 0.5s;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(27, 92, 167, 0.3);
}

.social-btn a.g-btn {
  background: #f44336;
  box-shadow: 0 3px 6px 0 rgba(244, 67, 54, 0.3);
}

.social-btn a.g-btn:hover,
.social-btn a:hover {
  background: var(--dark);
  color: #fff;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 30, 0.3);
}

.social-btn a i {
  font-size: 20px;
  vertical-align: top;
  margin-right: 10px;
}

.main-banner {
  position: relative;
  overflow: hidden;
  display: flex;
  background: url("../images/main-bg.jpg") no-repeat center center;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.main-banner .shap-top {
  position: absolute;
  right: 0;
  top: 0;
  margin: -220px;
  max-width: 450px;
}

.main-banner .shap-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: -70px;
  max-width: 160px;
}

.main-banner h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 62px;
  font-weight: 700;
  line-height: 120%;
  margin: 0 0 10px;
}

.main-banner .inner {
  padding: 0 50px;
  max-width: 100%;
}

.main-banner h4 {
  font-size: 30px;
  text-transform: uppercase;
  color: var(--primary);
  margin: 0 0 45px;
}

.app-btn-set a {
  border: 2px solid #fff;
  background: var(--dark);
  border-radius: 50px;
  padding: 0 30px 0 60px;
  position: relative;
  margin-right: 15px;
  font-size: 18px;
  line-height: 120%;
  height: 56px;
  display: inline-flex;
  transition: all 0.5s;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  color: #fff;
  font-weight: 700;
}

.app-btn-set a.active {
  border-color: var(--secondary);
  background: var(--secondary);
}

.app-btn-set a.active:hover,
.app-btn-set a:hover {
  border-color: var(--primary);
  background: var(--primary);
  color: #fff;
}

.scrim-tournament-btn {
  position: absolute;
  bottom: 15px;
}

.scrim-applyCode-Popup {
  height: 14em !important;
}

.scrim-leaderboard-btn {
  position: relative;
  left: 30%;
  bottom: 6px;
  background-color: var(--primary);
}

.app-btn-set a i {
  position: absolute;
  left: 24px;
  top: 0;
  display: flex;
  bottom: 0;
  align-items: center;
  font-size: 32px;
  width: 26px;
}

.app-btn-set a span {
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 120%;
}

html .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: var(--secondary) !important;
}

html .mCSB_inside>.mCSB_container {
  margin-right: 18px;
}

.login-image {
  max-width: 220px;
  margin-bottom: 35px;
}

.otp_input_single_box {
  box-shadow: none;
  background-color: var(--white);
  border: 2px solid #d5d7e3;
  color: var(--dark);
  height: 42px;
  font-family: "Proxima Nova";
  font-size: 17px;
  border-radius: 50px;
  transition: all 0.5s ease;
  padding: 0 20px;
  font-weight: 600;
  margin: 0 auto 20px auto;
  display: flex;
  text-align: center;
}

.otp-field {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.otp-field input {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #d5d7e3;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.15);
  font-size: 30px;
  text-align: center;
}

.otp-field .field+.field {
  margin-left: 15px;
}

.otp-title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 10px;
}

/* .form-group .date-field .form-control {
    padding-right: 60px;
} */
.date-field {
  position: relative;
}

.form-group .date-field .icon {
  position: absolute;
  right: 24px;
  top: 0;
  display: flex;
  align-items: center;
  height: 48px;
  width: 25px;
  pointer-events: none;
}

html .ui-selectmenu-button.ui-button {
  display: block;
  width: 100%;
  border: 1px solid #d5d7e3 !important;
  border-radius: 50px;
  background: #fff !important;
  font-size: 17px;
  font-weight: 600;
  color: #70717a;
  padding: 0 50px 0 20px;
  font-family: "Proxima Nova";
  letter-spacing: normal;
  line-height: 46px;
  position: relative;
}

html .ui-selectmenu-button.ui-button .ui-icon {
  display: none;
}

html .ui-selectmenu-button.ui-button:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: -4px;
  bottom: 0;
  margin: auto 30px;
  width: 9px;
  height: 9px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
}

html .ui-selectmenu-button.ui-button.ui-selectmenu-button-open:after {
  transform: rotate(-135deg);
  top: 3px;
}

html .ui-state-active,
html .ui-widget-content .ui-state-active,
html .ui-widget-header .ui-state-active,
html a.ui-button:active,
html .ui-button:active,
html .ui-button.ui-state-active:hover {
  border: 1px solid var(--secondary);
  background: var(--secondary);
}

.form-group .form-control[disabled] {
  background: #e8e9eb;
}

.wallet-btn a {
  font-size: 12px;
  color: #fff;
  line-height: 120%;
  display: block;
}

.wallet-btn a:hover {
  color: var(--primary);
}

.wallet-btn img {
  display: block;
  max-width: 25px;
  margin: 0 auto 3px;
}

.header .wallet-btn {
  margin-top: 0px;
}

.back-btn {
  width: 33px;
}

.back-btn a {
  cursor: pointer;
}

.with-footer .inner-wrap .body {
  border-radius: 35px;
  padding: 45px 12px 25px;
}

.with-footer .inner-wrap {
  padding-bottom: 60px;
  padding-top: 80px;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.footer-menu {
  padding: 0 10px 10px;
  list-style: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-align: center;
  margin: 0;
  font-size: 12px;
}

.footer-menu .menu-item {
  width: 18%;
}

.footer-menu .menu-item a {
  display: block;
  color: #70717a;
  line-height: 120%;
}

.footer-menu .menu-item .icon {
  width: 22px;
  height: 22px;
  margin: 0 auto 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(0) invert(1);
  opacity: 0.35;
  transition: all 0.3s;
}

.footer-menu .menu-item.active a,
.footer-menu .menu-item a:hover {
  color: var(--primary);
}

.footer-menu .menu-item.active a .icon,
.footer-menu .menu-item a:hover .icon {
  filter: brightness(1) invert(0);
  opacity: 1;
}

.footer-menu .menu-item-center a {
  color: var(--secondary);
  display: block;
  line-height: 120%;
}

.footer-menu .menu-item-center .icon {
  width: 54px;
  height: 54px;
  background: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 4px;
  padding: 16px;
}

.main-slider .item {
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  /* box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1); */
  margin: 5px;
  position: relative;
}

.main-slider .item>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-slider .item .item_caption {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  padding: 10px;
  color: #fff;
}

.main-slider .item_caption h6 {
  font-size: 13px;
  margin: 0;
  font-style: italic;
}

.main-slider .item_caption h4 {
  margin: 0;
  font-weight: 900;
  font-size: 21px;
  line-height: 110%;
}

.main-slider .item_caption h5 {
  font-size: 13px;
  margin: 0;
}

.main-slider .item .item_bottom_caption {
  background: var(--secondary);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 4px 10px;
  color: #fff;
  font-size: 9px;
  line-height: 110%;
}

.main-slider .item.slide_dark .item_caption {
  color: var(--dark);
}

.main-slider .item.slide_dark .item_bottom_caption {
  background: rgba(0, 0, 0, 0.15);
}

.icon-title {
  margin: 20px 0 14px;
  line-height: 26px;
  font-size: 20px;
  font-weight: 600;
}

html[dir="rtl"] .icon-title {
  text-align: right;
}

.icon-title .icon {
  display: inline-block;
  vertical-align: top;
  padding-right: 14px;
  margin-right: 14px;
  position: relative;
}

.icon-title .icon img {
  max-height: 26px;
  display: block;
}

html[dir="rtl"] .icon-title .icon {
  margin-left: 14px;
  padding-left: 14px;
  padding-right: 0;
  margin-right: 0;
}

.icon-title .icon:before {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  width: 2px;
  background: var(--primary);
  margin: 5px 0;
}

html[dir="rtl"] .icon-title .icon:before {
  left: 0;
  right: 100%;
}

.tournament_items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
}

.tournament_items .item {
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 8px;
}

.tournament_items .box {
  background: var(--primary);
  color: var(--dark);
  border-radius: 5px;
  padding: 4px;
  margin-top: 12px;
  box-shadow: 0 3px 8px 0 rgba(255, 198, 9, 0.3);
}

.tournament_items .box.box_sky {
  background: var(--sky-primary);
  box-shadow: 0 3px 8px 0 rgba(67, 242, 255, 0.3);
}

.tournament_items .box.box_sky .img-caption h3:after {
  background: var(--sky-primary);
}

.tournament_items .box.box_green {
  background: var(--green-primary);
  box-shadow: 0 3px 8px 0 rgba(9, 255, 196, 0.3);
}

.tournament_items .box.box_green .img-caption h3:after {
  background: var(--green-primary);
}

.tournament_items .box .img {
  position: relative;
  margin-top: -12px;
  border-radius: 5px;
  overflow: hidden;
  height: 90px;
  margin-bottom: 4px;
}

.tournament_items .box .img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tournament_items .box .img-caption {
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
  bottom: 0;
  padding: 30px 5px 5px;
  background: linear-gradient(to bottom,
      rgba(7, 11, 40, 0) 0%,
      rgba(7, 11, 40, 1) 100%);
}

.tournament_items .box .img-caption h5 {
  font-size: 8px;
  margin: 0;
  font-weight: 600;
}

.tournament_items .box .img-caption h6 {
  color: var(--light);
  font-size: 10px;
  margin: 0 0 2px;
}

.tournament_items .box .img-caption h3 {
  font-weight: 900;
  font-size: 12px;
  margin: 0;
}

.tournament_items .box .brdr-arrow-0:after {
  content: "";
  display: block;
  height: 3px;
  width: 35px;
  background: var(--primary);
  margin-top: 2px;
}

.tournament_items .box .brdr-arrow-1:after {
  content: "";
  display: block;
  height: 3px;
  width: 35px;
  background: #43f2ff;
  margin-top: 2px;
}

.tournament_items .box .brdr-arrow-2:after {
  content: "";
  display: block;
  height: 3px;
  width: 35px;
  background: #09ffc4;
  margin-top: 2px;
}

.tournament_items .box .brdr-arrow:after {
  content: "";
  display: block;
  height: 3px;
  width: 35px;
  background: var(--primary);
  margin-top: 2px;
}

.tournament_items .box .txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tournament_items .box .txt h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 700;
}

.tournament_items .box .txt .winning-label {
  font-size: 6px;
}

.tournament_items .box .txt h4 img {
  max-width: 14px;
  vertical-align: top;
  margin-top: 3px;
}

.all_games_page_all_games_title {
  font-size: 14px;
  display: flex;
  width: 100%;
  height: 18px;
  justify-content: center;
  /* transform: translateX(100%); */
}

.games_items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3px;
  margin-right: -3px;
  margin-bottom: 2rem;
}

.games_items .item {
  width: 33.33%;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 6px;
}

.games_items .box {
  background: var(--dark);
  padding: 3px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  overflow: hidden;
}

.games_items .box h3 {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 3px 0 3px;
}

.games_items .box .img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 100px;
}

.games_items .box .img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.games_items .box .img-caption {
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
  bottom: 0;
  padding: 20px 2px 3px;
  background: linear-gradient(to bottom,
      rgba(7, 11, 40, 0) 0%,
      rgba(7, 11, 40, 1) 100%);
  /* display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between; */
}

.games_items .img-caption-item p {
  color: var(--light);
}

.games_items .box .img-caption .img-caption-item {
  width: 100%;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  position: relative;
  padding-left: 4px;
  border-right: 1px solid #999;
  display: flex;
}

html[dir="rtl"] .games_items .box .img-caption .img-caption-item {
  /* flex-direction: row-reverse; */
  /* justify-content: flex-end; */
  border-left: 1px solid #999;
  padding-left: 4px;
}

.games_items .box .img-caption .img-caption-item:last-child {
  border-right: 0;
}

html[dir="rtl"] .games_items .box .img-caption .img-caption-item:last-child {
  border-left: 0;
}

.img-caption-item p {
  margin-left: 5px;
}

html[dir="rtl"] .img-caption-item p {
  margin-right: 5px;
}

.games_items .box .img-caption .img-caption-item img {
  /* display: block;
  position: absolute;
  left: 2px;
  top: 4px; */
  width: 13px;
}

.games_items .img-caption .img-caption-item span {
  font-size: 12px;
  line-height: 120%;
  /* vertical-align: middle; */
  font-weight: 600;
}

.back-btn img {
  max-width: 24px;
}

html[dir="rtl"] .back-btn img {
  transform: rotate(180deg);
}

.inner-wrap .header h2 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.header .wallet-btn {
  min-width: 33px;
}

.text-header .header .wallet-btn {
  margin-top: 0;
}

.text-header .inner-wrap .header {
  align-items: center;
}

.text-header .inner-wrap {
  padding-top: 90px;
}

.text-header .inner-wrap .body {
  padding-top: 20px;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 20px;
}

.title .icon {
  vertical-align: top;
  margin-right: 10px;
  display: inline-block;
}

html[dir="rtl"] .title .icon {
  margin-left: 10px;
}

.title .icon img {
  display: block;
  max-height: 26px;
}

.games_items.games_big_items {
  margin-left: -5px;
  margin-right: -5px;
  /* height: calc(100% - 50px); */
  overflow: auto;
}

.games_items.games_big_items::-webkit-scrollbar {
  display: none;
}

.games_items.games_big_items .item {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.games_items.games_big_items .box h3 {
  font-size: 16px;
  margin: 3px 0 5px;
  font-weight: 900;
}

.games_items.games_big_items .box .img {
  height: 150px;
}

.games_items.games_big_items .box .img>img {
  height: 104%;
}

.bottom_btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;

}

.inner-wrap .body.bottom-btn-body {
  padding-bottom: 80px;
}

.popup_show .inner-wrap:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  background: #070b28;
  opacity: 0.75;
}

.custom-popup {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  padding: 20px;
  border-radius: 40px 40px 0 0;
  transform: translateY(100%);
  transition: all 0.3s;
  z-index: 10;
}

.custom-popup.show {
  transform: translateY(0%);
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
}

.form-note {
  font-size: 13px;
}

.custom-popup .popup-head {
  position: relative;
  background: var(--primary);
  display: flex;
  align-items: center;
  text-align: center;
  height: 60px;
  padding: 20px 50px;
  margin: -20px -20px 20px;
  border-radius: 40px 40px 0 0;
  justify-content: center;
}

.custom-popup .popup-body {
  overflow: auto;
  /* padding: 0 10px 60px 10px; */
}

.custom-popup .popup-body::-webkit-scrollbar {
  display: none;
}

.bg-yellow {
  background-color: var(--primary);
}

.popup-head h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.popup-head .close-popup {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  padding: 0;
  height: 26px;
  margin: auto 15px;
  color: var(--secondary);
  font-size: 13px;
  font-weight: 600;
}

html[dir="rtl"] .popup-head .close-popup {
  left: -26rem;
}

.close-popup {
  position: absolute;
  right: 0;
  top: 10px;
  background: transparent;
  border: 0;
  padding: 0;
  height: 26px;
  z-index: 3;
  margin: auto 15px;
  color: var(--secondary);
  font-size: 13px;
  font-weight: 600;
}

.close-popup img {
  max-height: 100%;
  margin-left: 5px;
}

.announcement {
  position: relative;
  border-radius: 50px;
  background: var(--secondary);
  padding: 8px 15px 8px 70px;
  margin: 0 0 20px;
  color: #fff;
  box-shadow: 0 3px 6px 0 rgba(249, 44, 44, 0.25);
  font-size: 11px;
  line-height: 120%;
}

.announcement img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 18px;
  max-width: 35px;
}

.announcement b {
  display: block;
  line-height: 120%;
  font-size: 15px;
  margin-bottom: 3px;
}

#tournament_main .box {
  background: var(--dark);
  color: var(--light);
}

#tournament_main .box h3 {
  color: var(--light);
}

.contest-tournament-list.contest-list .box h3 marquee {
  margin-top: 5px;
}

#tournament_main .box h3 marquee {
  margin-top: 5px;
}

.contest-list .box {
  background: var(--primary);
  border-radius: 20px;
  margin: 0 0 35px;
  padding: 12px;
}

.contest-list .box h3 {
  position: relative;
  font-size: 13px;
  line-height: 120%;
  margin: 0 0 10px;
  padding-right: 70px;
}

#tournament_main .box h3 span {
  background: var(--primary);
  color: var(--dark);
}

.contest-list .box h3 span {
  display: block;
  min-width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--dark);
  color: #fff;
  font-size: 9px;
  padding: 0 8px;
  border-radius: 0 50px 50px 50px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.25);
  margin: -12px;
  line-height: 20px;
}

html[dir="rtl"] .contest-list .box h3 {
  text-align: right;
  padding-right: 0;
  padding-left: 70px;
}

html[dir="rtl"] .contest-list .box h3 span {
  right: 95%;
  left: 0;
  text-align: right;
  border-radius: 50px 0 50px 50px;
}

.contest-list .box .inner_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.scrim-summary-card {
  justify-content: flex-start !important;
}

.scrim-balance-button {
  background: var(--secondary);
  color: #fff;
  font-weight: 600;
  padding: 0 10px;
  border-radius: 50px;
  line-height: 32px;
  font-size: 1rem;
}

.scrim-title-btn-width {
  width: 36%;
}

.coin-used-button {
  color: black;
  background-color: var(--color_white);
}

.fw-8 {
  font-weight: 950;
}

.joined-scrim-right-border {
  border-right: 2px solid #f8f9fa;
}

html[dir="rtl"] .joined-scrim-right-border {
  border-left: 2px solid #f8f9fa;
  border-right: none;
}

.contest-list .box .inner_row .inner_item {
  width: 25%;
  font-size: 0.75rem;
  padding: 0 12px;
  line-height: 120%;
}

.tournament_details .box .inner_row .inner_item {
  width: 25%;
  font-size: 0.75rem;
  padding: 0 4px;
  line-height: 120%;
}

.contest-list .box .inner_row .inner_item+.inner_item {
  border-left: 1px solid #070b28;
}

html[dir="rtl"] .contest-list .box .inner_row .inner_item+.inner_item {
  border-right: 1px solid #070b28;
  border-left: none;
  /* text-align: right; */

}

#tournament_main .box .inner_row .inner_item+.inner_item {
  border-left: 1px solid var(--light);
}

html[dir="rtl"] #tournament_main .box .inner_row .inner_item+.inner_item {
  border-right: 1px solid var(--light);
  border-left: none
}

#tournament_main .box .bg-primary .inner_item+.inner_item {
  border-left: 1px solid var(--dark);
}

html[dir="rtl"] #tournament_main .box .bg-primary .inner_item+.inner_item {
  border-right: 1px solid var(--dark);
  border-left: none
}

.contest-list .box .inner_row .inner_item b {
  display: block;
}

.contest-list .box .inner_row {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  margin: 0 -12px;
}

html .bg-dark {
  background-color: var(--dark) !important;
}

html .bg-primary {
  background-color: var(--primary) !important;
  color: var(--dark);
}

.border-top-secondary {
  border-top: 1px solid var(--secondary);
}

.contest-list .box .inner_row.bg-dark {
  color: #fff;
}

.contest-list .box .inner_row.bg-dark .inner_item+.inner_item {
  border-color: #ffffff;
}

.avalible_wrap {
  margin-top: 5px;
}

.avalible_wrap p {
  font-size: 12px;
}

#tournament_main .avalible_wrap p {
  color: var(--light);
}

.avalible_wrap .inner {
  box-shadow: 0 1px 6px 0 rgba(7, 11, 40, 0.2);
  background: #fff;
  height: 5px;
  border-radius: 50px;
  overflow: hidden;
  margin-bottom: 4px;
  position: relative;
}

#tournament_main .avalible_wrap .inner span {
  background-color: var(--primary);
}

.tournament_items .owl-theme .owl-nav {
  position: absolute;
  top: -50%;
  display: flex;
  right: 1%;
  width: 12%;
  justify-content: space-between;
}

.tournament_items .owl-theme .owl-nav .owl-prev {
  left: 355px;
}

/* .tournament_items .owl-theme .owl-nav .owl-next{
    
} */
.avatars_main .owl-theme .owl-nav {
  position: absolute;
  top: -14%;
  display: flex;
  right: 5%;
  width: 12%;
  justify-content: space-between;

}

html[dir="rtl"] .avatars_main .owl-theme .owl-nav {
  right: 85%;
}

.avatars_main .owl-theme .owl-stage-outer .owl-stage {
  padding-left: 0 !important;
}

.avatars_main .owl-prev {
  /* position: absolute;
  left: 350px; */
}

.reward_cat_main .owl-theme .owl-nav {
  position: absolute;
  top: -36%;
  display: flex;
  right: 10%;
  width: 12%;
  justify-content: space-between;
  background-color: transparent !important;
}

html[dir="rtl"] .reward_cat_main .owl-theme .owl-nav {
  right: 80%;
}

.reward_cat_main .owl-theme .owl-stage-outer .owl-stage {
  padding-left: 0 !important;
}

.reward_cat_main .owl-prev {
  /* position: absolute;
  left:30px */
}

.join-team {
  background: var(--dark);
  height: 100%;
  border-radius: 0px;
}

.captain-box {
  background: var(--primary);
  min-height: 180px;
  margin: 25px;
  border-radius: 20px;
}

.captain-lable {
  position: absolute;
  right: 0px;
  background: var(--dark);
  color: var(--light);
  padding: 3px 10px;
  border-radius: 0px 12px 12px;
  font-size: 12px;
}

.captain-lable-center {
  position: absolute;
  left: 45px;
  top: 59px;
  width: 80%;
  height: 35px;
  background: var(--light);
  color: var(--dark);
  padding: 4px 10px;
  border-radius: 20px 20px 20px 20px;
  font-size: 16px;
}

.captain-lable-bottom-left {
  position: absolute;
  left: 0px;

  width: 43%;
  height: 70px;
  background: var(--dark);
  color: var(--light);
  padding: 3px 10px;
  border-radius: 20px 20px 0px 20px;
  font-size: 14px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.captain-lable-bottom {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 43%;
  height: 70px;
  background: var(--dark);
  color: var(--light);
  padding: 3px 10px;
  border-radius: 20px 20px 20px 0px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.player-box {
  background: var(--dark);
  min-height: 180px;
  margin: 25px;
  border-radius: 20px;
}

.player-lable {
  position: absolute;
  right: 0px;
  background: var(--secondary);
  color: var(--light);
  padding: 3px 10px;
  border-radius: 0px 12px 12px;
  font-size: 12px;
}

.player-img-center {
  position: absolute;
  left: 10px;
  top: 35px;
}

.player-apply-btn {
  position: absolute;
  right: 17px;
  top: 45px;
  background: var(--primary);
  color: var(--dark);
  padding: 6px 18px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
}

.player-remove-btn {
  position: absolute;
  right: 17px;
  top: 45px;
  background: var(--secondary);
  color: var(--white);
  padding: 6px 18px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  height: 35px;
}

.player-img-center img {
  width: 55px;
}

.player-lable-center .form-control {
  position: absolute;
  display: block;
  width: 75%;
  height: calc(1em + 0.75rem);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #495057;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: 33px;
  top: 3px;
}

.player-lable-center {
  position: absolute;
  left: 75px;
  top: 45px;
  width: 78%;
  height: 35px;
  background: var(--light);
  color: var(--dark);
  padding: 4px 10px;
  border-radius: 20px 20px 20px 20px;
  font-size: 16px;
}

.player-lable-bottom-left {
  position: absolute;
  left: 0px;

  width: 43%;
  height: 70px;
  background: var(--primary);
  color: var(--dark);
  padding: 3px 10px;
  border-radius: 20px 20px 0px 20px;
  font-size: 14px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.player-lable-bottom {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 43%;
  height: 70px;
  background: var(--primary);
  color: var(--dark);
  padding: 3px 10px;
  border-radius: 20px 20px 20px 0px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.level_box .avalible_wrap .inner span {
  background: var(--secondary);
  /* background-color: var(--primary); */
}

.inner-title {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.avalible_wrap .inner span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  /* background: var(--secondary); */
  background-color: var(--primary);
}

html[dir="rtl"] .avalible_wrap .inner span {
  right: 0;
}

.contest_foot {
  background: var(--secondary);
  color: #fff;
  margin: 10px 8px -30px;
  border-radius: 5px 5px 15px 15px;
  display: flex;
}

.contest_foot .inner {
  width: 50%;
  font-size: 12px;
  /* padding: 50px; */
  line-height: 120%;
  border-right: 1px solid var(--dark);
  /* border-right: 1px solid var(--dark); */
}

html[dir="rtl"] .contest_foot .inner {
  border-left: 1px solid var(--dark);
  border-right: none;
  text-align: right;
  padding: 0 20px;
}

.contest_foot .inner:last-child {
  border-right: 0;
}

html[dir="rtl"] .contest_foot .inner:last-child {
  border-left: 0;
}

.contest_foot .inner b {
  display: block;
}

#tournament_main .contest_foot .inner a {
  color: var(--dark);
  font-weight: bold;
  font-size: 14px;
}

#tournament_main .contest_foot .inner b {
  font-weight: bold;
  font-size: 12px;
}

.contest_foot .inner a {
  display: block;
  text-align: center;
  font-size: 13px;
  line-height: 24px;
  color: #fff;
}

.contest_foot .inner a:hover {
  color: var(--primary);
}

.contest-list .box.green_box {
  background: var(--green-primary);
}

.contest-list .box.green_box .contest_foot {
  background: var(--dark);
}

.green_box .contest_foot .inner {
  border-right-color: var(--green-primary);
}

.custom-popup .popup-head.popup-head2 {
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid var(--secondary);
}

.custom-popup .popup-head.popup-head2 .close-popup {
  margin-right: 0;
}

.filter_tab .list-group {
  background: var(--secondary);
  border-radius: 0 20px 20px 0;
  padding: 10px 0;
  min-height: 100%;
}

.filter_tab .list-group .list-group-item {
  border-radius: 0;
  border: 0;
  position: relative;
  background: transparent;
  color: #fff;
  font-weight: 600;
  line-height: 120%;
  padding: 10px 14px;
  font-size: 13px;
  margin-bottom: 5px;
}

.filter_tab .list-group .list-group-item.active,
.filter_tab .list-group .list-group-item:hover {
  color: var(--primary);
}

.filter_tab .list-group .list-group-item.active:before {
  height: 100%;
}

.filter_tab .list-group .list-group-item:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s;
  bottom: 0;
  margin: auto;
  width: 2px;
  height: 0;
  background: #fff;
}

.filter_tab .tab-content {
  border-radius: 20px 0 0 20px;
  background: rgba(255, 255, 255, 0.3);
  min-height: 100%;
  padding: 20px;
}

.filter-search {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 18px;
}

.filter-search input {
  display: block;
  width: 100%;
  border: 0;
  background: transparent;
  color: #000;
  font-size: 12px;
  height: 26px;
  padding: 0 0 0 20px;
}

.filter-search img {
  display: block;
  position: absolute;
  left: 3px;
  top: 7px;
  width: 10px;
}

.checkbox_wrap label {
  position: relative;
  margin: 0 0 20px;
  display: block;
  padding: 0 0 0 20px;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
}

.checkbox_wrap label input[type="checkbox"] {
  display: none;
}

.checkbox_wrap label span {
  position: absolute;
  left: 0;
  top: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.checkbox_wrap label input:checked~span {
  border-color: var(--secondary);
  background: var(--secondary);
}

.checkbox_wrap label span:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  transition: all 0.3s;
  opacity: 0;
  height: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  margin: 1px;
}

.checkbox_wrap label input:checked~span:after {
  opacity: 1;
}

.confirmation {
  background: var(--secondary);
  color: #fff;
  padding: 12px;
  line-height: 120%;
  margin: 0 0 10px;
  font-size: 13px;
}

.confirmation b {
  display: block;
  font-size: 16px;
  line-height: 120%;
}

.total_table table {
  width: 100%;
  font-size: 14px;
}

.profile_level_table.table th {
  border-top: 1px solid var(--dark);
  border-bottom: 1px solid var(--dark);
}

.profile_level_table.table td {
  border-top: 1px solid var(--dark);
}

.total_table table td,
.total_table table th {
  padding-top: 5px;
  padding-bottom: 5px;
}

.total_table table td {
  font-size: 15px;
}

.total_table table .total_tr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.total_table table .total_tr td,
.total_table table .total_tr tr {
  padding-top: 10px;
  padding-bottom: 10px;
}

.join-small-text {
  font-size: 10px;
  line-height: 120%;
}

.contest_foot .inner.inner-text a {
  display: inline;
  font-size: inherit;
  line-height: inherit;
}

.contest_foot.contest_details_foot .inner {
  width: auto;
  flex-grow: 1;
}

.list-group.contest-tab {
  border: 1px solid rgba(213, 215, 227, 0.5);
  border-radius: 50px;
  margin: 0 0 20px;
  display: flex;
  background: #fff;
  flex-direction: row;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
}

.list-group.contest-tab .list-group-item {
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  display: block;
  text-align: center;
  padding: 0 10px;
  line-height: 32px;
  font-weight: normal;
}

.list-group.contest-tab .list-group-item.active {
  background: var(--dark);
  color: #fff;
  font-weight: 600;
}

.contest-list.contest-tournament-list .box .img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
}

.contest-list.contest-tournament-list .img>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contest-list.contest-tournament-list .img-caption {
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  bottom: 0;
  padding: 40px 2px 7px;
  background: linear-gradient(to bottom,
      rgba(7, 11, 40, 0) 0%,
      rgba(7, 11, 40, 1) 100%);
}

.contest-list.contest-tournament-list .box .inner_row {
  /* margin-left: -10px; */
  padding-top: 5px;
  padding-bottom: 5px;
  /* border-radius: 10px 0 0 10px; */
}

.contest-list.contest-tournament-list .box .inner_row .inner_item {
  width: 25%;

  /* // width: 33.33%; */
  padding: 0 10px;
}

html[dir="rtl"] .contest-list.contest-tournament-list .box .inner_row .inner_item {
  text-align: right;
}

.inner_item_medium {
  width: 33.33% !important;
}

.contest-list.contest-tournament-list .box .inner_row .inner_item.inner_item_big {
  width: 66.67%;
}

.contest-list.contest-tournament-list .box .inner_row.bg-dark {
  border-radius: 8px 0 0 8px;
}

.contest-tournament-list .contest_foot {
  border-radius: 5px;
  margin: 5px 0 0 0;
}

#tournament_main .contest_foot {
  border-radius: 5px 5px 25px 25px;
  top: 30px;
  position: relative;
  background-color: var(--primary);
  color: var(--dark);
}

#tournament_main .contest_foot .inner:first-child {
  border-right: 1px solid var(--dark);
  /* border-right: 1px solid #fff; */
}

html[dir="rtl"] #tournament_main .contest_foot .inner:first-child {
  border-left: 1px solid var(--dark);
  border-right: none;
  /* border-right: 1px solid #fff; */
}

.contest-tournament-list .contest_foot .inner {
  width: 50%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.contest-tournament-list .contest_foot .inner:first-child {
  width: 50%;
}

.contest-tournament-list .contest_foot .inner a {
  font-size: 14px;
  line-height: 23px;
}

.tournament_contest_row {
  margin-bottom: -25px;
  height: 100%;

}

html[dir="rtl"] .tournament_contest_row {
  margin-left: -15px;
  margin-right: -15px;
}

.contest-tournament-list.contest-list .box h3 {
  font-size: 15px;
}

.contest-tournament-list.contest-list .box h3 span {
  padding-top: 2px;
  padding-bottom: 2px;
}

.contest-tournament-list .img-caption h5 {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
}

.contest-list.contest-tournament-list .box {
  margin-bottom: 30px;
}

.p-small {
  font-size: 11px;
}

.radio_wrap label {
  position: relative;
  margin: 0 0 20px;
  display: block;
  padding: 0 0 0 22px;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
}

.radio_wrap label input[type="radio"] {
  display: none;
}

.radio_wrap label span {
  position: absolute;
  left: 0;
  top: 3px;
  border: 1px solid var(--dark);
  width: 14px;
  height: 14px;
  border-radius: 15px;
}

.radio_wrap label input:checked~span {
  border-color: var(--secondary);
}

.radio_wrap label span:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  transition: all 0.3s;
  opacity: 0;
  height: 8px;
  margin: 2px;
  border-radius: 10px;
  background: var(--secondary);
}

html[dir="rtl"] .radio_wrap label span {
  /* left: 100%; */
  right: 0;
}

.ps_radio:after {
  background: #C5A260 !important;
}

.radio_wrap label input:checked~span:after {
  opacity: 1;
}

.banner-img {
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 25%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.list-group.main-tab {
  /* border: 1px solid rgba(213,215,227,0.5); */
  border-radius: 50px;
  margin: 0 0 20px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
  background: var(--secondary);
}

.list-group.main-tab .list-group-item {
  border-radius: 50px;
  border: 0;
  font-size: 16px;
  font-weight: 1000;
  display: block;
  text-align: center;
  padding: 0 10px;
  line-height: 36px;
  font-weight: normal;
  background: transparent;
  position: relative;
  color: var(--light);
  margin: 0;
  font-size: 16px;
  font-weight: 1000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-group.main-tab .list-group-item.active {
  color: var(--light);
  background: var(--dark);
  font-weight: 1000;
  pointer-events: none;
  cursor: not-allowed;
}

.list-group.main-tab .list-group-item {
  font-weight: 1000;
}

.list-group.main-tab.leader-board .list-group-item.active {
  color: var(--dark);
  background: var(--light);
  font-weight: 1000;
}

.list-group.main-tab .list-group-item.active:before,
.list-group.main-tab .list-group-item:last-child:before {
  display: none;
}

.list-group.main-tab .list-group-item:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  height: 16px;
  background: var(--dark);
  margin: auto 0;
  opacity: 0.2;
}

html[dir="rtl"] .list-group.main-tab .list-group-item:before {
  right: 100%;
}

.contest_toggle_txt {
  display: none;
}

.contest-list.contest-toggle .box {
  border-radius: 50px;
  margin-bottom: 12px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
}

.contest_toggle_btn {
  font-size: 17px;
  padding: 0 10px;
  position: relative;
  line-height: 22px;
  cursor: pointer;
}

html[dir="rtl"] .contest_toggle_btn {
  text-align: right;
}

.contest_toggle_btn span img {
  vertical-align: top;
  max-width: 18px;
  margin-right: 2px;
  margin-top: 1px;
}

.contest_toggle_btn span {
  position: absolute;
  right: 0px;
  top: 9px;
  /* background: var(--dark); */
  font-size: 12px;
  color: #fff;
  padding: 3px 35px 3px 12px;
  line-height: 20px;
  border-radius: 0 50px 50px 50px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.25);
  /* margin: -12px; */
}

.contest_toggle_btn span:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 8px;
  width: 16px;
  height: 16px;
  background: url("../images/contest_toggle.svg") no-repeat 0 0;
  background-size: 100% auto;
}

html[dir="rtl"] .contest_toggle_btn span:before {
  transform: rotate(180deg);
  /* text-align: right; */
}

.show .contest_toggle_txt {
  display: block;
}

.contest-list.contest-toggle .box.show {
  border-radius: 20px;
  margin-bottom: 30px;
}

.contest-list.contest-toggle .box.show .contest_toggle_btn {
  margin-bottom: 10px;
}

html[dir="rtl"] .contest-list.contest-toggle .box.show .contest_toggle_btn {
  text-align: right;
}

.show .contest_toggle_btn span:before {
  transform: rotate(90deg);
}

html[dir="rtl"] .show .contest_toggle_btn span:before {
  transform: rotate(90deg);
}

.friendship_items .box .icon {
  display: flex;
  background: var(--primary);
  width: 44px;
  height: 44px;
  margin: -20px auto 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border: 2px solid var(--dark);
}

.friendship_items .box {
  background: var(--secondary);
  color: #fff;
  text-align: center;
  border-radius: 50px;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
  font-size: 14px;
  line-height: 26px;
  position: relative;
  padding: 1px 0 4px;
  margin-top: 20px;
}

.r-wrap {
  max-width: 260px;
  margin: 0 auto 10px;
}

.r-wrap .r-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.r-wrap .r-image .img {
  width: 14%;
  cursor: pointer;
}

.r-range .ui-widget.ui-widget-content {
  border: 0;
  height: 4px;
  background: var(--dark);
  margin: 5px 0;
  border-radius: 5px;
}

.r-range .ui-slider .ui-slider-handle {
  border: 0;
  top: -11px;
  height: 19px;
  cursor: pointer;
  left: -8px;
  width: 16px;
  background: url(../images/range-btn.svg) no-repeat center center;
  background-size: auto 100%;
}

.round-head {
  color: #fff;
  background: var(--dark);
  border-radius: 25px 25px 0 0;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.round-head .item {
  width: 25%;
  padding: 10px 0;
}

.round-box {
  background: var(--primary);
  border-radius: 25px;
}

.round-box .dtc {
  padding: 30px 12px;
}

.round-chart {
  display: flex;
  margin: 0 -15px;
}

.round-chart .col {
  padding: 0 15px;
}

.round-chart .item {
  background: var(--dark);
  text-align: center;
  color: #fff;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 20px;
}

.round-chart .grp {
  margin-bottom: 20px;
  position: relative;
}

.round-chart .item:last-child,
.round-chart .grp:last-child {
  margin-bottom: 0;
}

.round-chart .grp .label {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 9px;
  line-height: 20px;
  height: 20px;
  text-align: right;
  left: 0;
  right: -15px;
}

.round-chart .grp .label img {
  width: 15px;
  vertical-align: top;
  margin-top: 6px;
  margin-left: 4px;
}

.round-chart .col:nth-child(2),
.round-chart .col:nth-child(3),
.round-chart .col:nth-child(4) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.round-chart .col:nth-child(2) .grp,
.round-chart .col:nth-child(3) .grp,
.round-chart .col:nth-child(4) .grp {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
}

.round-chart .grp:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  border-top: 1px solid var(--dark);
  border-right: 1px solid var(--dark);
  border-bottom: 1px solid var(--dark);
  margin: 10px -20px;
}

.round-chart .grp:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 1px;
  background: var(--dark);
  margin: auto -30px;
}

.round-chart .col:nth-child(2) .grp:before {
  top: 14%;
  bottom: 14%;
}

.round-chart .col:nth-child(3) .grp:before {
  top: 20%;
  bottom: 20%;
}

.round-chart .col:nth-child(4) .grp:before,
.round-chart .col:nth-child(4) .grp:after {
  display: none;
}

.round-chart .col_blue .item,
.round-chart .col_blue .grp:after {
  background: #0032ff;
}

.round-chart .col_blue .label {
  color: #0032ff;
}

.round-chart .col_blue .grp:before {
  border-color: #0032ff;
}

.round-chart .col_red .item {
  background: #f92c2c;
}

.round-chart .col_red .label {
  color: #f92c2c;
}

.round-chart .col_red .grp:before {
  border-color: #00b406;
}

.round-chart .col_red .grp:after {
  background: #00b406;
}

.round-chart .col_green .item {
  background: #00b406;
}

.player-icons-captain {
  background: var(--primary);
  border-radius: 20px 20px 0px 0px;
  padding: 10px 15px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);

  text-align: center;
  line-height: 120%;
  font-size: 11px;
}

.player_captain {
  background: var(--dark);
  color: var(--light);

  border-radius: 0px 0px 10px 10px;
  position: relative;

  padding-bottom: 10px;
}

.player_captain .item:first-child {
  border-radius: 0px 0px 10px 10px;
}

.player_captain .item {
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-size: 12px;
  line-height: 120%;
}

/* .player_captain .item:hover {
    background:#efefef;
} */
.player_captain .item.active {
  color: #fff;
  background: var(--secondary);
}

.player_captain .item .inner {
  width: 20%;
  text-align: center;
}

.player_captain .item .inner.inner_big {
  text-align: left;
  position: relative;
  padding-left: 40px;
  width: 40%;
  min-height: 34px;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.player_captain .item .inner.inner_big .icon {
  width: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

/* .player-icons-captain [class*="col"] + [class*="col"] .item {
    border-left: 1px solid var(--dark);
} */
.player_icons {
  background: var(--primary);
  border-radius: 50px;
  padding: 10px 15px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.05);
  margin: 0 0 20px;
  text-align: center;
  line-height: 120%;
  font-size: 11px;
}

.player_icons .icon,
.player-icons-captain .icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  margin: 0 auto 3px;
}

.player_icons [class*="col"]+[class*="col"] .item {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

html[dir="rtl"] .player_icons [class*="col"]+[class*="col"] .item {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-left: none;
}


.player_list {
  border: 1px solid #dfe4e9;
  border-radius: 20px;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.player_list .item+.item {
  border-top: 1px solid #dfe4e9;
}

.player_list .item:first-child {
  border-radius: 20px 20px 0 0;
}

.player_list .item {
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-size: 12px;
  line-height: 120%;
}

.player_list .item:hover {
  background: #efefef;
}

.player_list .item.active {
  color: #fff;
  /* background:var(--secondary); */
  background: var(--dark);
}

.player_list .item .inner {
  width: 20%;
  text-align: center;
}

.player_list .item .inner.inner_big {
  text-align: left;
  position: relative;
  /* padding-left: 40px; */
  width: 40%;
  min-height: 34px;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

html[dir="rtl"] .player_list .item .inner.inner_big {
  /* text-align: right; */
}

html[dir="rtl"] .player_list .item .inner.inner_big .icon {
  margin-left: 1rem !important;
  margin-right: 0 !important;
}

.player_list .item .inner.inner_big .icon {
  width: 35px;
  margin-right: 1rem;
  /* position: absolute;
  left: 0;
  top: 0; */
}

.player_list .item .inner.inner_big b {
  margin-top: 0.5rem;
}

.load_more .btn {
  font-size: 13px;
  line-height: 34px;
  padding: 0 20px;
}

html .btn.btn-primary {
  background: var(--primary);
  color: var(--dark);
  box-shadow: 0 3px 6px 0 rgba(7, 11, 30, 0.1);
}

html .btn.btn-primary:hover {
  background: var(--secondary);
  color: #fff;
}

.player_list .load_more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -16px;
}

.tab_content {
  border: 1px solid #dfe4e9;
  border-radius: 15px;
  padding: 15px;
}

ul.cust_list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #82878d;
}

ul.cust_list li {
  position: relative;
  padding-left: 18px;
  margin: 0 0 10px;
}

ul.cust_list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  width: 8px;
  height: 8px;
  background: var(--secondary);
  border-radius: 50%;
}

.wrapper>.row>[class*="col"]:last-child {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: auto;
  width: auto;
}

.wrapper>.row>[class*="col"]:first-child {
  flex-basis: 475px;
  max-width: 475px;
  min-width: 475px;
}

.wrapper>.row {
  flex-wrap: nowrap;
}

html .bg-secondary {
  background: var(--secondary) !important;
}

html .bg-green {
  background: var(--green-primary) !important;
}

html .bg-sky {
  background: var(--sky-primary) !important;
}

.shadow-primary {
  box-shadow: 0 3px 6px 0 rgba(255, 198, 9, 0.3);
}

.shadow-secondary {
  box-shadow: 0 3px 6px 0 rgba(249, 44, 44, 0.3);
}

.shadow-green {
  box-shadow: 0 3px 6px 0 rgba(9, 255, 196, 0.25);
}

.shadow-sky {
  box-shadow: 0 3px 6px 0 rgba(67, 242, 255, 0.25);
}

.shadow-black {
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.1);
}

.current_balance_box {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.current_balance_box img {
  max-width: 26px;
  margin-right: 10px;
}

.current_balance_box b {
  font-size: 18px;
  font-weight: 800;
}

.text-gray {
  color: #70717a;
}

a.text-gray:hover {
  color: var(--secondary);
}

.ammount_label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 18px;
  flex-wrap: wrap;
}

.ammount_label .item {
  border-radius: 50px;
  line-height: 42px;
  width: 31%;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.5s;
}

.ammount_label .item:hover {
  color: #fff;
  background: var(--dark) !important;
}

.balance_links a {
  display: flex;
  line-height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 30px;
  border-radius: 50px;
  border: 1px solid #efefef;
  margin: 0 3px;
  color: var(--dark);
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}

.balance_links a:hover {
  background: var(--dark);
  color: #fff;
}

.balance_links .item {
  margin-bottom: 15px;
}

.balance_links a .icon {
  display: block;
  width: 18px;
}

.balance_links {
  margin-top: 18px;
}

.date_lable {
  text-align: center;
  margin: 15px 0;
  color: #70717a;
  font-size: 14px;
}

.recent_transaction_items .item {
  margin-bottom: 10px;
}

.recent_transaction_items .accordion-header {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.1);
  border: 1px solid #d5d7e3;
  border-radius: 50px;
  padding: 10px 22px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  background: #fff;
  position: relative;
  z-index: 2;
  transition: all 0.5s;
}

.recent_transaction_items .accordion-header[aria-expanded="true"] {
  border-color: var(--secondary);
  background: var(--secondary);
  color: #fff;
}

.recent_transaction_items .accordion-header img {
  max-width: 26px;
  margin-right: 12px;
  max-height: 26px;
}

html[dir="rtl"] .recent_transaction_items .accordion-header img {
  margin-left: 12px;
  margin-right: 0;

}

.recent_transaction_items .accordion-header b {
  font-size: 16px;
  margin-right: 12px;
  min-width: 30px;
  line-height: 120%;
}

.recent_transaction_items .accordion-header[aria-expanded="true"] b {
  color: #fff !important;
}

.recent_transaction_items .accordion-header .icon {
  margin-left: auto;
  width: 18px;
}

html[dir="rtl"] .recent_transaction_items .accordion-header .icon {
  margin-right: auto;
  margin-left: 10px;


}

.recent_transaction_items .accordion-header[aria-expanded="true"] .icon {
  transform: rotate(-90deg);
}

html[dir="rtl"] .recent_transaction_items .accordion-header[aria-expanded="true"] .icon {
  transform: rotate(90deg);
}

.tc_jvic_arrow_img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent_transaction_items .txt {
  background: var(--primary);
  padding: 35px 20px 24px;
  box-shadow: 0 3px 6px 0 rgba(255, 198, 9, 0.3);
  margin-top: -20px;
  border-radius: 0 0 20px 20px;
  margin-bottom: 30px;
  position: relative;
  font-size: 13px;
}

.recent_transaction_items .txt table {
  width: 100%;
}

.recent_transaction_items .txt table th,
.recent_transaction_items .txt table td {
  padding-bottom: 5px;
}

.recent_transaction_items .txt .recent_tra_links {
  left: 0;
  right: 0;
  bottom: -15px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.recent_transaction_items .txt .recent_tra_links a {
  display: flex;
  width: 48%;
  border: 2px solid var(--primary);
  border-radius: 50px;
  line-height: 120%;
  padding: 6px 6px;
  align-items: center;
  color: var(--dark);
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
}

.recent_transaction_items .txt .recent_tra_links a:hover {
  color: #fff;
  background: var(--dark) !important;
}

.recent_transaction_items .txt .recent_tra_links a img {
  max-height: 18px;
  margin-right: 5px;
}

a.text-dark:hover {
  color: var(--secondary) !important;
}

.recent_transaction_items .txt hr {
  border-color: #ecb500;
  margin: 10px 0;
}

.recent_transaction_items.more_tab_items .accordion-header {
  font-size: 14px;
}

.complaint_title {
  background: var(--dark);
  color: #fff;
  padding: 12px;
  text-align: center;
  line-height: 140%;
  font-size: 16px;
  border-radius: 50px;
  margin: 0 0 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.complaint_list .item {
  padding: 12px;
  margin: 0 0 15px;
  border: 1px solid #dfe4e9;
  border-radius: 8px;
  cursor: pointer;
}

.complaint_list .box_row {
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;
}

.complaint_list .box_row .box {
  width: 31%;
  text-align: center;
  padding: 10px;
  border-radius: 7px;
  font-size: 10px;
  line-height: 140%;
}

.complaint_list .box_row .box .icon {
  width: 40px;
  height: 40px;
  background: #fff;
  margin: -20px auto 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}

.complaint_list .box_row .box b {
  display: block;
  font-size: 125%;
  line-height: 140%;
  margin-top: 3px;
}

.complaint_list .txt {
  color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 12px;
}

html[dir="rtl"] .complaint_list .txt {
  text-align: right;
}

.complaint_list .txt h6 {
  font-size: 16px;
  font-weight: 900;
  margin: 0 0 5px;
}

.complaint_list .txt p {
  font-size: 12px;
  line-height: 140%;
  color: #fff;
}

.cmt .item+.item {
  margin-top: 25px;
}

.cmt .item .txt {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  border-radius: 17px 17px 17px 0;
  padding: 12px;
}

.cmt .item .txt p {
  font-weight: 600;
  margin: 0 0 8px;
}

.cmt .item .txt .time {
  font-size: 12px;
}

.cmt .item {
  position: relative;
  padding-left: 45px;
}

.cmt .item .avtar {
  width: 35px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50px;
  overflow: hidden;
}

.cmt .item .avtar img {
  display: block;
}

.cmt {
  margin-top: 30px;
  margin-bottom: 10px;
}

.cmt .item.our_item {
  padding-left: 0;
  text-align: right;
}

.cmt .item.our_item .txt {
  border-radius: 17px 17px 0 17px;
  color: #fff;
}

.cmt .item.our_item .txt p {
  text-align: left;
  color: #fff;
}

.cmt .item+.item.item_loader {
  margin-top: 7px;
}

.cmt .item.item_loader .inner {
  display: inline-flex;
  vertical-align: top;
  max-width: 100%;
  border-radius: 0 20px 20px 20px;
  padding: 15px 12px;
  align-items: center;
}

.item_loader .inner span {
  width: 4px;
  height: 4px;
  background: var(--dark);
  display: block;
  border-radius: 5px;
  margin: 5px;
  animation-name: item_loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.item_loader .inner span:nth-child(2) {
  animation-delay: 0.2s;
}

.item_loader .inner span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes item_loader {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rank-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  text-align: center;
}

.rank-wrap .item {
  width: 28%;
}

.rank-wrap .item.item_big {
  width: 38%;
}

.rank-wrap .item .icon {
  position: relative;
  margin: 0 10px -44px;
}

.rank-wrap .item .icon span {
  position: absolute;
  right: 50%;
  top: 0;
  margin-right: -30px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  line-height: 16px;
  font-size: 9px;
  font-weight: 600;
}

.rank-wrap .item .text {
  padding: 40px 5px 10px;
  border-radius: 30px 30px 30px 30px;
}

.rank-wrap .item h4 {
  font-size: 12px;
  font-weight: 900;
  margin: 0 3px;
}

.rank-wrap .item p {
  font-size: 10px;
  margin: 0 0 8px;
}

.rank-wrap .item .rank_label {
  margin: 0 -5px -10px;
  background: var(--dark);
  color: #fff;
  font-weight: 900;
  font-size: 13px;
  line-height: 24px;
  border-radius: 8px;
}

.rank-wrap .item.item_big h4 {
  font-size: 16px;
}

.rank-wrap .item.item_big h4,
.rank-wrap .item.item_big p {
  color: #fff;
}

.rank-wrap .item.item_big .rank_label {
  font-size: 15px;
  line-height: 35px;
  margin-bottom: -15px;
  border-radius: 16px;
  position: relative;
}

.rank-wrap .item.item_big .icon span {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  margin-right: -37px;
}

.rank-wrap .item.item_big .icon {
  margin-bottom: -65px;
}

.rank-wrap .item.item_big .text {
  padding-top: 60px;
}

.player_list_lable {
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 24px;
}

.player_list_lable .inner+.inner {
  border-left: 1px solid #deab03;
}

html[dir="rtl"] .player_list_lable .inner+.inner {
  border-right: 1px solid #deab03;
  border-left: none;
}

.player_list_lable .inner {
  width: 20%;
  min-height: 24px;
  padding: 0 10px;
  text-align: center;
}

.player_list_lable .inner.inner_big {
  width: 40%;
  text-align: left;
}

html[dir="rtl"] .player_list_lable .inner.inner_big {
  text-align: right;
}

.reward-rank-wrap {
  display: flex;
  border-radius: 30px 30px 10px 10px;
  margin: 14px 0 20px;
  text-align: center;
}

.reward-rank-wrap .item {
  width: 33.33%;
  padding: 12px 5px;
}

.reward-rank-wrap h4 {
  margin: 0;
  font-size: 23px;
  font-weight: 900;
}

.reward-rank-wrap p {
  font-size: 12px;
  margin: 0 0 0px;
}

.reward-rank-wrap .icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 5px;
  background: rgba(255, 255, 255, 0.33);
  padding: 5px;
  border-radius: 50%;
}

.reward-rank-wrap .icon img {
  max-height: 100%;
}

.reward-rank-wrap .item.item_big .icon {
  background: rgba(255, 255, 255, 0.15);
}

.reward-rank-wrap .item.item_big {
  position: relative;
  margin-top: -14px;
  color: #fff;
  border-radius: 15px 15px 0 0;
}

.reward-rank-wrap .item.item_big p {
  color: #fff;
}

.reward_day_list .item {
  border: 1px solid #dfe4e9;
  display: flex;
  margin: 0 0 10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.1);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.reward_day_list .item.bg-secondary {
  box-shadow: 0 3px 6px 0 rgba(249, 44, 44, 0.3);
  color: #fff;
}

.reward_day_list .item.bg-sky {
  box-shadow: 0 3px 6px 0 rgba(67, 242, 255, 0.25);
}

.reward_day_list .item .inner.inner_big {
  width: 34%;
}

.reward_day_list .item .inner.last_inner {
  width: 27%;
}

.reward_day_list .item .inner img {
  max-width: 13px;
  margin-right: 2px;
}

.reward_day_list .item .inner {
  width: 22%;
  padding: 11px 6px;
  position: relative;
}

.reward_day_list .item .inner:before {
  display: block;
  width: 1px;
  background: var(--dark);
  opacity: 0.3;
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  margin: auto;
}

.reward_day_list .item .inner:last-child:before,
.reward_day_list .item .inner.inner_day:before {
  display: none;
}

.reward_day_list .item .inner.inner_day {
  background: var(--primary);
  border-radius: 8px 0 0 8px;
  font-weight: 600;
  width: 18%;
}

.reward_day_list .item.active .inner.inner_day {
  background: var(--dark);
  color: #fff;
}

.reward_day_list .item b {
  font-size: 20px;
  font-weight: 700;
}

.report_issue_toggle {
  cursor: pointer;
}

.popup-body-scroll {
  max-height: 70vh;
  overflow: auto;
}

.popup_avtar_wrap {
  margin: -55px 0 20px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

.popup_show .popup_avtar_wrap {
  opacity: 1;
  visibility: visible;
}

.popup_avtar_wrap .img {
  border: 2px solid #fff;
  background: #fff;
  width: 74px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto 5px;
}

.popup_avtar_wrap h5 {
  font-size: 16px;
  font-weight: 800;
}

.r_issue_popup h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 15px;
}

.r_issue_popup h4 {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin: 0 0 15px;
}

.emoji_wrap {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  line-height: 120%;
  margin: 0 0 40px;
  text-align: center;
}

.emoji_wrap .icon {
  width: 54px;
  margin: 0 auto 7px;
}

.r_checkbox_box {
  border-radius: 8px;
  padding: 1px;
}

.r_checkbox_box .icon {
  width: 70px;
  margin: -36px auto 3px;
}

.r_checkbox_box h5 {
  color: #fff;
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  margin: 0 0 20px;
}

html .btn.btn-secondary {
  background: var(--secondary);
}

html .btn.btn-secondary:hover {
  background: var(--dark);
}

.signup_button {
  background: var(--secondary);
  color: var(--white);
}

.signup_button:hover {
  background: #ff0000;
  color: var(--white);
}

.checkbox_big_wrap .checkbox_item {
  padding: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.checkbox_big_wrap .checkbox_item label {
  margin: 0;
  font-size: 14px;
  color: #fff;
  padding-left: 30px;
}

.checkbox_big_wrap .checkbox_item label span {
  width: 18px;
  height: 18px;
  top: 2px;
  border-color: rgba(255, 255, 255, 0.5);
}

.checkbox_big_wrap .checkbox_item label input:checked~span {
  border-color: var(--primary);
  background: var(--primary);
}

.checkbox_big_wrap .checkbox_item label span:after {
  border-color: var(--dark);
  margin: 3px;
  width: 11px;
  height: 6px;
}

.prize_pool p {
  color: var(--dark);
  font-size: 14px;
  margin-bottom: 5px;
  font-size: 18px;
}

.winning_breakup_popup_toggle {
  cursor: pointer;
}

.w-90 {
  width: 90%;
  margin: 0 auto !important;
}

.prize_pool h6 {
  color: var(--dark);
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total_table.total_table_border th,
.total_table.total_table_border td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
}

.total_table.total_table_border tr:last-child th,
.total_table.total_table_border tr:last-child td {
  border-bottom: 0;
}

/* Enter Card Details Style Start */
.cvv-saved {
  text-align: center;
  margin: 10px 0 25px 0;
}

.cvv-saved p {
  color: #70717a;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.cvv-saved i {
  margin-right: 5px;
  display: inline-block;
}

.cvv-saved i img {
  width: 15px;
}

.search-bank {
  margin: 25px 0 20px 0;
}

.search-bank .form-control {
  background: url(../images/search.svg);
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 50px;
  box-shadow: 0px 3px 6px #070b281a;
  border: 1px solid #d5d7e3;
}

.bank-name-list ul {
  margin: 0px;
  width: 100%;
  padding: 0 12px;
  box-shadow: 0px 3px 6px #070b281a;
  border: 1px solid #d5d7e3;
  border-radius: 10px;
}

.bank-name-list li {
  list-style: none;
}

.bank-name-list li a {
  width: 100%;
  color: var(--dark);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  line-height: 44px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #070b281a;
}

.bank-name-list li:last-child a {
  border: 0px;
}

.bank-name-list li a svg {
  width: 18px;
}

.cvv-icon .icon {
  position: absolute;
  top: 37px;
  right: 15px;
}

/* Pan Style Start */
.pan-note {
  text-align: center;
  margin-top: 20px;
}

.pan-note p {
  color: #70717a;
  font-size: 15px;
}

.pan-btn {
  margin-bottom: 5px;
}

.pan-btn a {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #070b281a;
  border: 1px solid #d5d7e3;
  border-radius: 50px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 600;
  color: var(--dark);
}

.pan-btn a img {
  max-width: 26px;
  margin-right: 10px;
}

.upload-pan {
  background: var(--secondary) !important;
  color: #fff !important;
  padding: 17px 25px !important;
}

/* Bank Style Start */
.bank-details {
  text-align: center;
  width: 95%;
  margin: 25px auto 20px;
  background: var(--primary);
  box-shadow: 0px 3px 6px #ffc60940;
  border-radius: 10px;
  padding: 15px;
}

.bank-details h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.bank-details p {
  font-size: 16px;
  margin-bottom: 0px;
}

.proceed-link {
  margin: 20px 0;
}

.proceed-link h5 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.pay-mth-icon {
  text-align: center;
  margin-bottom: 40px;
}

/* Legality Style Start */
.legality-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px #070b280d;
  border: 1px solid #dfe4e9;
  border-radius: 20px;
  padding: 20px 15px;
}

.legality-card h4 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.legality-card p {
  font-size: 15px;
  color: #82878d;
  margin: 0 0 20px 0;
  line-height: 22px;
}

.legality-card p:last-child {
  margin-bottom: 0px;
}

/* Change Password Style Start */
.add-banner {
  margin: 20px 0 30px 0;
  border-radius: 10px;
  overflow: hidden;
}

.add-banner img {
  width: 100%;
}

.promo-code-title {
  text-align: center;
  margin: 0 0 20px 0;
}

.promo-code-title h5 {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
}

/* Mobile Menu Style Start */
#burgerBtn {
  margin-top: 5px;
  border-top: 2px solid #ffffff;
  height: 25px;
  width: 30px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -khtml-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#burgerBtn:before {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 30px;
  left: 0;
  background: #ffffff;
  top: 10px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -khtml-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#burgerBtn:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 30px;
  left: 0;
  background: #ffffff;
  bottom: 0;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -khtml-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

#nav {
  position: absolute;
  z-index: 10;
  list-style-type: none;
  margin: 100px 0 0 20px;
  padding: 0;
  overflow: hidden;
}

#nav li {
  width: 0;
}

#nav li a {
  width: 100%;
  display: inline-block;
  color: var(--white);
  font-size: 16px;
  line-height: 35px;
  font-weight: 600;
}

#nav li a:hover {
  color: var(--secondary);
}

#nav li+li {
  margin-left: -40px;
}

#nav li+li+li {
  margin-left: -80px;
}

#nav li+li+li+li {
  margin-left: -120px;
}

#nav li+li+li+li+li {
  margin-left: -160px;
}

.navigation #nav li {
  width: 200px;
  margin: 0;
  -webkit-transition: all 0.6s ease-in;
  -moz-transition: all 0.6s ease-in;
  -khtml-transition: all 0.6s ease-in;
  -o-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
}

.navigation #burgerBtn {
  border-color: transparent;
}

.navigation #burgerBtn:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 33px;
  left: -2px;
  background: var(--secondary);
}

.navigation #burgerBtn:after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -khtml-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 11px;
  width: 33px;
  left: -2px;
  background: var(--secondary);
}

.body {
  border-radius: 35px;
  background: #fff;
  visibility: visible;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -khtml-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.menumain.navigation .body {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -khtml-transform: scale(0.85);
  -o-transform: scale(0.85);
  transform: scale(0.85);
  visibility: hidden;
}

html .dropdown-menu {
  max-width: 280px;
}

.body::-webkit-scrollbar,
.custom_scroll::-webkit-scrollbar,
.custom_scroll_leader::-webkit-scrollbar {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
}

.body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

.custom_scroll::-webkit-scrollbar-track {
  display: none;
}

.custom_scroll::-webkit-scrollbar-thumb {
  display: none;
}

.body::-webkit-scrollbar-thumb,
.custom_scroll_leader::-webkit-scrollbar-thumb {
  /* background: #f92c2c; */
  width: 4px;
}

.body::-webkit-scrollbar-thumb:hover,
.custom_scroll::-webkit-scrollbar-thumb:hover,
.custom_scroll_leader::-webkit-scrollbar-thumb:hover {
  background: #f92c2c;
  width: 4px;
}

.scroll_bg::-webkit-scrollbar {
  width: 4px;
  height: 100%;
  margin: 0 auto;
  border-radius: 46px;
}

.scroll_bg::-webkit-scrollbar-track {
  display: block;
  border-radius: 46px;
  /* background: #C5A260 !important; */
}

.scroll_bg::-webkit-scrollbar-thumb {
  background: #C5A260 !important;
  border-radius: 46px;
  display: block;

}

.intlPhoneInput {
  margin-left: 55% !important;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 10px 20px !important;
  z-index: 999 !important;
}

.intl-tel-input.allow-dropdown .flag-container {
  width: auto !important;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
}

.select {
  display: block;
  box-shadow: none;
  background-color: var(--white);
  border: 1px solid #d5d7e3;
  color: #70717a;
  height: 48px;
  font-family: "Proxima Nova";
  font-size: 17px;
  border-radius: 50px;
  transition: all 0.5s ease;
  padding: 0 20px;
  font-weight: 600;
  width: 100%;
}

.file_upload {
  position: relative;
}

.redClick {
  color: #ff0000;
  cursor: pointer;
}

.scrim-link {
  font-size: 19px;
  text-decoration: underline;
  color: var(--dark) !important;
}

.Input,
.p-FauxInput {
  border-radius: 50px !important;
}

.loader {
  text-align: center;
  z-index: 9999;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: transparent;
}

.left {
  left: 0;
}

.pointer {
  cursor: pointer;
}

.button-links {
  border: none;
  background-color: transparent;
  font-weight: bolder;
}

/* .home-featured {
    bottom: 4em !important;
} */

.rules {
  padding: 0 !important;
  min-height: 39em;
  overflow-x: clip;
  /* overflow-y: auto; */
  overflow-y: hidden;
  background-color: var(--primary);
}

.rules .popup-head {
  margin: 0 !important;
}

.error {
  color: var(--secondary);
}

.join .popup-body {
  text-align: center;
}

.join .popup-head h3 {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.round_time {
  background-color: var(--secondary);
  color: var(--light);
  width: 90%;
  padding: 10px;
  margin: 0 auto;
}

.join .radio_wrap {
  height: 150px;
  overflow: auto;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.join .radio_wrap label {
  line-height: unset;
  display: flex;
  padding: 10px;
  margin: 0;
}

.border_black {
  border-bottom: 1px solid rgb(51 51 51 / 20%);
}

.join .radio_wrap label:last-child {
  border-bottom: unset;
}

.join .radio_wrap label span {
  top: 13px;
}

.join .radio_wrap label p {
  text-align: left;
  margin-left: 20px;
}

html[dir="rtl"] .join .radio_wrap label p {
  text-align: right;
  margin-right: 20px;
}

.join .select_msg {
  color: var(--secondary);
}

.join .next {
  width: 90% !important;
  margin: 0 auto;
}

.confirm .popup-body {
  width: 100%;
  margin: 0 auto;
}

.confirm .popup-body .game_det {
  height: 30px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.confirm .popup-body .game_det span:first-child {
  float: left;
}

.confirm .popup-body .game_det span:last-child {
  float: right;
}

.confirm .popup-body .round_time {
  width: 90%;
  margin: 0 auto;
  color: var(--light) !important;
  text-align: left;
}

html[dir="rtl"] .confirm .popup-body .round_time {
  text-align: right;

}

.confirm .popup-body .round_time p {
  color: var(--light) !important;
  font-weight: bold;
}

.confirm .popup-body .round_time p:first-child {
  font-size: 20px;
  margin-bottom: 0;
}

.confirm .popup-body .round_time p:last-child {
  font-weight: 400;
}

.confirm {
  min-height: 35em;
}

.fee {
  width: 90%;
  margin: 0 auto;
}

.fee p {
  border-bottom: 1px solid rgb(51 51 51 / 20%);
  margin-bottom: 0;
  padding: 10px 0;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

p.terms_join {
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
}

p.level_note {
  color: var(--secondary);
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
}

.fee p b {
  height: 20px;
}

.fee p b:first-child {
  float: left;
}

.fee p b:last-child {
  float: right;
}

.rounds .round_name {
  margin-bottom: 10px;
}

.rounds .owl-prev {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3px !important;
  margin-left: 20px !important;
  /* display: block !important; */
  background: var(--dark) !important;
  color: #fff !important;
  border-radius: 20px !important;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

.round1 .owl-prev {
  background-color: transparent !important;
}

.owl-prev span {
  font-size: 1.5rem;
}

.rounds .owl-next {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3px !important;
  /* left: 26em; */
  right: 0;
  margin-left: 20px !important;
  /* display: block !important; */
  background: var(--dark) !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.round1 .owl-next {
  background-color: transparent !important;
}

.round_time img {
  width: 15px;
}

.coin_img {
  width: 12px;
}

.no_tournament {
  /* font-size: 25px;
    text-align: center;
    margin-top: 10px; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.scrim-buttons {
  margin-top: 3rem;
}

.game_tounament .box {
  background-color: var(--dark);
}

.game_tounament .contest_foot {
  background-color: var(--primary);
  color: var(--dark);
}

.game_tounament .inner_row {
  background-color: var(--primary);
}

.game_tounament .box h3 {
  color: var(--light);
}

.game_tounament .avalible_wrap .inner span {
  background-color: var(--primary);
}

.game_tounament .box h3 span {
  background-color: var(--primary);
  color: var(--dark);
  font-weight: 600;
}

.contest_joining_popup {
  color: var(--dark) !important;
  font-weight: bold;
}

.contest .inner_item {
  width: 24.33% !important;
}

.contest .inner_big {
  width: 80% !important;
}

.contest_single {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  width: 85%;
  margin: 5px auto !important;
  border-radius: 5px 5px 15px 15px !important;
}

.tournament_details .box h3 {
  padding-right: 0px;
}

.tournament_details .box {
  background-color: var(--dark) !important;
  color: var(--light);
}

.tournament_details .box .inner_row .inner_item+.inner_item {
  border-left: 1px solid var(--light);
}

html[dir="rtl"] .tournament_details .box .inner_row .inner_item+.inner_item {
  border-right: 1px solid var(--light);
}

.tournament_details .box .bg-primary .inner_item+.inner_item {
  border-left: 1px solid var(--dark);
}

html[dir="rtl"] .tournament_details .box .bg-primary .inner_item+.inner_item {
  border-right: 1px solid var(--dark);
}

.tournament_details .inner_big_50 {
  width: 50% !important;
}

.tournament_details .inner_big_25 {
  width: 25% !important;
}

.tournament_details .inner_big_30 {
  width: 30% !important;
}

.tournament_details .inner_big_20 {
  width: 20% !important;
}

.tournament_details .avalible_wrap p {
  color: var(--light) !important;
}

.coin_img_big {
  width: 25px;
  margin-right: 10px;
}

html[dir="rtl"] .coin_img_big {
  margin-left: 10px;
}

.coin_img_medium {
  width: 24px;
}

.winner {
  height: 20em !important;
}

.country_drop .css-1okebmr-indicatorSeparator {
  display: none;
}

.country_drop .css-tlfecz-indicatorContainer,
.country_drop .css-1gtu0rj-indicatorContainer {
  padding: 0px !important;
  padding-right: 3px !important;
}

.country_drop .css-1s2u09g-control,
.country_drop .css-1pahdxg-control {
  cursor: pointer !important;
  height: 48px;
  width: 6em;
  background-color: var(--white);
  border: 2px solid #d5d7e3;
  color: #70717a;
  font-size: 15px;
  border-radius: 50px;
  transition: all 0.5s ease;
}

.sign_in_country_code_popup_button {
  cursor: pointer !important;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: 2px solid #d5d7e3;
  color: var(--dark);
  font-size: 15px;
  border-radius: 50px;
  transition: all 0.5s ease;
}

.category_popup {
  cursor: pointer !important;
  height: 42px;
  min-height: 42px;
  max-height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border: 2px solid #d5d7e3;
  color: var(--dark);
  font-size: 15px;
  border-radius: 50px;
  transition: all 0.5s ease;
}

.college-popup {
  cursor: pointer !important;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 2px solid #d5d7e3;
  color: var(--light);
  font-size: 15px;
  border-radius: 50px;
  transition: all 0.5s ease;
  padding-left: 1rem;
}

.sign_in_country_code_popup_button div {
  width: 100%;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
  margin-left: 3rem;
}

.country_list_popup_component {
  width: 100%;
  height: 100vh;
  z-index: 11;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.category_list_popup_component {
  width: 100%;
  height: 100vh;
  z-index: 11;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.country_list_popup {
  width: 100%;
  height: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  border: none;
  border-radius: 70px 70px 0 0;
}

.country_list_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.country_list_popup_header_text {
  font-size: 19px;
  font-weight: 1000;
}

.country_list_popup_body {
  width: 100%;
  height: 75%;
  min-height: 75%;
  max-height: 75%;
  overflow: auto;
  margin-top: 10px;
  padding: 10px;
}

.country_list_popup_body_text {
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
  margin: 10px 0;
  cursor: pointer;
}

html[dir="rtl"] .country_list_popup_body_text {
  text-align: right;
}

.country_list_popup_body_text:hover {
  background-color: #d3d3d3;
  border-radius: 50px;
}

.country_list_popup_body::-webkit-scrollbar {
  display: none;
}

.country_list_popup_body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.css-1pahdxg-control:hover {
  border-color: var(--primary);
}

.country_drop .css-319lph-ValueContainer {
  width: 25px;
  padding: 0 0 0 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .animate {
    position: relative;
    animation: leftright 3s infinite alternate ease-in-out;
} */
.coin_featured img {
  width: 15px !important;
  height: 15px;
}

html[dir="rtl"] .coin_featured span {
  padding-right: 5px;
  font-size: 15px;
}

.coin_featured span {
  padding-left: 5px;
  font-size: 15px;
}

.coin_featured {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-weight: 700;
}

/* #contact_popup:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 5;
    background: #070B28;
    opacity: 0.75;
} */
.profile_insights_main {
  height: 93vh;
  margin-bottom: 60px;

  /* overflow: hidden; */
  background-color: var(--dark);
  color: var(--light);
  visibility: visible;
  transition: all 0.3s ease-in;
}

.profile_insights_main::-webkit-scrollbar {
  display: none;
}

.thead-current-form {
  background-color: var(--primary);
  color: var(--dark);
}

.profile-insights-current-form {
  color: var(--light);
  text-align: center;
  margin: 0 10px;
}

.profile-insights-current-form>table {
  border: 1px solid #393d54;
  color: var(--light);
}

thead.thead-current-form th {
  border: 0px;
}

.table-bordered th {
  border: 1px solid var(--primary);
  padding: 8px;
}

.table-bordered td {
  border: 1px solid #393d54;
  padding: 5px;
  color: var(--light);
}

.profile_edit_top {
  position: relative;
  top: 0;
  background: var(--light);
  color: var(--dark);

  border-radius: 40px;
  margin: 5px;
}

.profile_edit_top_inner {
  padding: 20px;
}

.profile_edit_bottom {
  position: relative;
  top: 0;
  color: var(--light);
  margin: 5px;
}

.profile_edit_bottom_inner {
  padding: 20px;
}

.profile_edit_bottom_inner ul {
  list-style-type: none;
}

.profile_edit_bottom_inner li {
  display: inline-block;
}

.profile_edit_bottom_inner input[type="radio"][id^="cb"] {
  display: none;
}

.profile_edit_bottom_inner label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.profile_edit_bottom_inner label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.profile_edit_bottom_inner label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.profile_edit_bottom_inner :checked+label {
  border-color: #ddd;
}

.profile_edit_bottom_inner :checked+label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

.profile_edit_bottom_inner :checked+label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}

.profile-stats-box {
  background: #1f2237;
  color: var(--primary);
  border-radius: 16px;
  padding: 5px;
  margin-top: 10px;
}

.chart-left-text {
  transform: rotate(-90deg);
  top: 33%;
  position: absolute;
}

.chart-bottom-text {
  text-align: center;
  font-weight: 600;
}

.load-more-btn {
  height: 30px;
  background: var(--dark);
  color: var(--light);
  border: 0;
  border-radius: 20px;
  width: 100%;
}

.scrolling-wrapper {
  overflow-x: auto;
  margin-bottom: 10px;
  height: -webkit-fill-available;
}

/* width */
.scrolling-wrapper::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.scrolling-wrapper::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

/* Handle */
.scrolling-wrapper::-webkit-scrollbar-thumb {
  background: #f92c2c;
}

/* Handle on hover */
.scrolling-wrapper::-webkit-scrollbar-thumb:hover {
  background: #f92c2c;
}

.profile-stats-box p {
  color: var(--primary);
  margin: 10px;
}

.profile_insights_top {
  position: relative;
  top: 0;

  padding-right: 20px;
  /* border-radius: 0 0 70% 70%; */
}

.profile_insights_1 {
  background-color: var(--dark);
  color: var(--light);
  padding-top: 10px;
  height: 100%;
  /* top: 45px; */
  /* position: absolute; */
  width: 100%;
  /* border-radius: 0 0 70% 70%; */
  margin-left: 0 !important;
  margin-top: 35px;
}

.profile_main {
  height: 91vh;
  margin-bottom: 60px;
  /* position: relative; */
  overflow: hidden;
  background-color: var(--dark);
  color: var(--light);
  visibility: visible;
  transition: all 0.3s ease-in;
  overflow-y: scroll;
}

.game_name_h5 {
  color: #ffc609;
  font-weight: bold;
}



.game_insights_hover:hover {
  color: var(--secondary) !important;
  cursor: pointer;
}

.game_insights_hover img {
  height: 32px;
  width: 32px;
  margin-left: 14px;
}

html[dir="rtl"] .game_insights_hover img {
  margin-right: 14px;
  margin-left: 0;
  transform: rotate(180deg);
}

.profile_top {
  color: var(--dark);
  padding-top: 10px;
  position: relative;
  top: 0;
  background-color: #ffc609;
  /* padding-right: 20px; */
  /* border-radius: 0 0 70% 70%; */
}

.user_list {
  color: var(--dark);
  position: relative;
}

.user_list .item {
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  font-size: 18px;
  line-height: 120%;
  border: 1px solid #dfe4e9;
  border-radius: 25px;
  margin-bottom: 10px;
}

.usersearchbar {
  width: 100%;
  text-align: center;
}

.usersearchbar i {
  position: absolute;
  right: 6%;
  top: 23px;
  background: var(--secondary);
  color: var(--light);
  padding: 9px;
  font-size: 25px;
  border-radius: 25px;
  cursor: pointer;
}

html[dir="rtl"] .usersearchbar i {
  right: 85%;
}

.usersearchbar input {
  /* border: unset; */
  border-radius: 25px;
  width: 90%;
  height: 50px;
  position: relative;
  top: 20px;
  padding: 5px 12px;
  background-color: var(--primary) !important;
  border: solid 1px;
}

.user_list .item .inner.inner_big {
  text-align: left;
  position: relative;
  padding-left: 40px;
  width: 100%;
  min-height: 34px;
  padding-top: 6px;
  vertical-align: bottom;
  display: flex;
}

html[dir="rtl"] .user_list .item .inner.inner_big {
  text-align: right;
  padding-right: 60px;
}

.user_list .item .inner.inner_big .icon {
  width: 32px;
  position: absolute;
  left: 0;
  top: 0;
}

html[dir="rtl"] .user_list .item .inner.inner_big .icon {
  left: 90%;
}

.profile_first {
  color: var(--dark);
  padding-top: 10px;
  height: 490px;
  min-height: 490px;
  max-height: 490px;
  top: 0;
  background-color: #ffc609;
  width: 100%;
  /* border-radius: 0 0 70% 70%; */
  margin-left: 0 !important;
}

.profile_second {
  height: 100%;
  background-color: var(--dark);
  color: var(--light);
  border-radius: 60px 60px 0 0;

  top: -70px;
}

.friend-remove {
  color: var(--dark);
  font-size: 12px;
  border: 1px solid #dfe4e9;
  padding: 5px 16px;
  border-radius: 25px;
}

.friends-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}

/* width */
.friends-custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.friends-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.friends-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.friends-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-custom-scrollbar {
  position: relative;
  height: 300px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

/* width */
.my-custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.my-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.my-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.my-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* width */
.profile_main::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.profile_main::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.4);
}

/* Handle */
.profile_main::-webkit-scrollbar-thumb {
  background: #f92c2c;
}

/* Handle on hover */
.profile_main::-webkit-scrollbar-thumb:hover {
  background: #f92c2c;
}

/* .collegiate-detail-tabs > .tab-content > .tab-pane {
  border: 1px solid #dee2e6;
} */

.table-body-border {
  border: 1px solid #dee2e6;
}

.list-group.college-tab {
  border: 1px solid var(--primary);
  border-radius: 50px;
  margin: 0px 0px 20px 20px;
  display: flex;
  background: var(--secondary);
  flex-direction: row;
  box-shadow: 0 3px 6px 0 rgb(7 11 40 / 5%);
  width: 90%;

  position: relative;
  top: -8px;
}

.list-group.college-tab .list-group-item {
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  display: block;
  text-align: center;
  padding: 0 10px;
  line-height: 42px;
  font-weight: normal;
  background-color: var(--secondary);
  color: var(--light);
  font-size: 16px;
  font-weight: 1000;
  z-index: 1;
}

.list-group.college-tab .list-group-item.active {
  background: var(--dark);
  color: #fff;
  font-weight: 600;
  z-index: 1;
}

.list-group.profile-tab {
  border: 1px solid var(--primary);
  border-radius: 50px;
  margin: 0px 0px 20px 65px;
  display: flex;
  background: var(--dark);
  flex-direction: row;
  box-shadow: 0 3px 6px 0 rgb(7 11 40 / 5%);
  width: 70%;

  position: relative;
  top: -19px;
}

html[dir="rtl"] .list-group.profile-tab {
  margin: 0px 65px 0 20px;

}

.table-thead {
  background-color: rgb(7, 11, 40);
  margin-top: 6px;
  margin-left: 0px;
  margin-right: 0px;
  height: 50px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.list-group.profile-tab .list-group-item {
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  display: block;
  text-align: center;
  padding: 0 10px;
  line-height: 32px;
  font-weight: normal;
  background-color: var(--dark);
  color: var(--light);
  font-size: 16px;
  font-weight: 1000;
}

.list-group.profile-tab .list-group-item.active {
  background: var(--secondary);
  color: #fff;
  font-weight: 600;
}

.profile_gamerji_name_row {
  width: 90%;
  height: 40px;
  border-radius: 0 0 30px 0;
  /* align-items: center; */
  color: white;
  position: relative;
  top: -8px;
}

.profile_gamerji_name {
  width: 140px;
  height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 10px;
  flex-grow: 1;
  background: var(--dark);
}

.profile_gamerji_name_right_triangle {
  width: 40px;
  height: 40px;
  background: var(--dark);
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 100%);
}

.profile_gamerji_name_right_upper_triangle {
  width: 14px;
  height: 7px;
  background: var(--dark);
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%, 0% 100%);
  position: absolute;
  right: -7px;
}

.profile_follower_followings_row {
  padding: 10px;
  padding-top: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile_followers_data {
  font-size: 24px;
  font-weight: 800;
}

.profile_followers_title {
  font-weight: 600;
}

.profile_follower_followings_divider {
  width: 1px;
  height: 45px;
  background-color: var(--primary);
}

.profile_followings_data {
  font-size: 24px;
  font-weight: 800;
}

.profile_followings_title {
  font-weight: 600;
}

.level_box {
  width: 200px;
  /* height: 200px;. */
  height: fit-content;
  align-items: center;
  background-color: #ffcf30;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: auto;
  border-radius: 20px;
  border: 1px solid var(--dark);
  /* border-color: #DFE4E9;
   border-width: 1px */
  /* box-shadow: 0px 0px 2px 0px #fff; */
}

html[dir="rtl"] .level_box {
  float: inline-end;
}

.profile_level_box_all_level {
  width: 100%;
  background-color: var(--secondary);
  border-radius: 0 0 20px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.profile_level_row {
  position: relative;
  top: -7px;
}

.profile_level_left_upper_triangle {
  width: 14px;
  height: 7px;
  background: var(--dark);
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%, 0% 100%);
  position: absolute;
  left: -7px;
  opacity: 0.5;
}

.profile_level_left_triangle {
  width: 25px;
  height: 25px;
  background: var(--dark);
  clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 0%);
}

.profile_level_details {
  height: 25px;
  background-color: var(--dark);
  height: 25px;
  align-self: center;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
}

.profile_level_right_triangle {
  width: 25px;
  height: 25px;
  background: var(--dark);
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 100%);
}

.profile_level_right_upper_triangle {
  width: 14px;
  height: 7px;
  background: var(--dark);
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%, 0% 100%);
  position: absolute;
  right: -7px;
  opacity: 0.5;
}

.account_main {
  height: 94vh;
  margin-bottom: 60px;
  /* position: relative; */
  overflow: hidden;
  background-color: var(--light);
  background: #fff;
  visibility: visible;
  transition: all 0.3s ease-in;
  border-radius: 0px 0px 35px 35px;
  padding-bottom: 30px;
}

.account_main::-webkit-scrollbar {
  display: none;
}

.account_first {
  color: var(--light);
  padding-top: 1em;
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  top: 0;
  background-color: var(--dark);
  width: 100%;
  border-radius: 0 0 70px 70px;
  margin-left: 0 !important;
}

.reward_title {
  padding-top: 1em !important;
  font-size: 20px;
  font-weight: bold;
  color: var(--light);
  height: 200px;
  top: 0;
  background-color: var(--dark);
  width: 100%;
  /* border-radius: 0 0 70% 70%; */
  margin-left: 0 !important;
}

.account_second {
  position: relative;
  top: -40px;
}

.account_first .avatar_img {
  padding-left: 10px;
  padding-right: 0;
}

.account_first .avatar_detail {
  padding-bottom: 3em;
}

.account_first .avatar_detail button {
  width: 7em;
  background-color: var(--green-primary);
  padding: 0;
  text-transform: capitalize;
}

.account_first .coin_total p {
  color: var(--light);
  font-size: 17px;
  margin-bottom: 0;
  font-weight: bold;
}

.coin_cash {
  align-items: center;
  width: 90%;
  /* box-shadow: 0px 0px 2px 0px #fff; */
}

.coin_cash_img {
  width: 10%;
  margin-right: 10px;
}

.coin_total span {
  font-weight: lighter;
}

.coin_val {
  font-size: 30px;
  font-weight: bold;
}

.coin_total {
  width: 30%;
}

.border-btm {
  border-bottom: 1px solid var(--gray);
}

.cash_box {
  width: 85%;
  margin: 0 auto 0px auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 15px 25px 15px;
  border: 1px solid #b4b6c4;
}

.reward {
  width: 85%;
  margin: 0 auto;
}

.reward .col-4>div {
  border-radius: 10px;
  cursor: pointer;
}

.reward .col-4>div>p {
  color: var(--light);
  padding-bottom: 15px;
}

.reward .col-4>div>.cash_icon_circle {
  position: relative;
  top: -15px;
  background-color: var(--primary);
}

.rupee_img_light {
  fill: var(--light);
  max-height: 15px;
}

.rupee_img_dark {
  fill: var(--dark);
  max-height: 15px;
}

.reward .col-6>div {
  border-radius: 10px;
  cursor: pointer;
}

.reward .col-6>div>p {
  color: var(--light);
  padding-bottom: 15px;
}

.reward .col-6>div>.cash_icon_circle {
  position: relative;
  top: -15px;
  background-color: var(--primary);
}

.brdr-rl {
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}

.cash_box b {
  font-size: 15px;
}

.cash_box b {
  font-size: 18px;
  font-weight: bolder;
}

.cash_icon_circle {
  background: #ffe89b;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.cash_icon_circle img {
  position: relative;
  top: 10px;
  width: 50%;
}

.cash_box i {
  color: var(--secondary);
  font-size: 10px;
  font-weight: 600;
  border: 1px solid var(--secondary);
  padding: 3px 7px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: -2px;
  left: 5px;
}

.withdrawal_btn {
  width: 30%;
  margin: 0 auto;
}

.withdrawal_btn .btn {
  background-color: var(--dark) !important;
  color: var(--light) !important;
  text-transform: capitalize;
  padding: 0;
}

.withdrawal_btn {
  width: 22%;
  margin: 0 auto;
  position: relative;
  right: 0;
  left: 0;
  top: -20px;
}

.info_acc {
  height: auto !important;
}

.reward_height .popup-body {
  height: 23em;
  overflow-y: auto;
}

.reward_height .popup-head>h3 {
  font-size: 25px;
}

.row.reward_main {
  width: 95%;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  height: 35px;
  align-items: center !important;
}

html .reward_first {
  background-color: var(--green-primary);
  height: 100%;
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
}

html[dir="rtl"] .reward_first {
  background-color: var(--green-primary);
  height: 100%;
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
}

.reward_first p {
  height: 100%;
  position: relative;
  top: 7px;
}

.reward_second {
  text-align: center;
  border-right: 1px solid #ccc;
}

html[dir="rtl"] .reward_second {
  text-align: center;
  border-left: 1px solid #ccc;
  border-right: none;
}

.reward_second h5 {
  font-weight: bold;
}

.reward_third i {
  border: 1px solid var(--secondary);
  border-radius: 20px;
  color: var(--secondary);
  padding: 4px 7px;
  font-size: 8px;
  cursor: pointer;
}

.reward_img {
  width: 15px;
}

.avatar_reward {
  width: 100%;
  height: 250px;
  text-align: center;
}

.avatar_reward img {
  height: 200px;
  text-align: center;
}

.row.reward_main.active_reward {
  background-color: var(--secondary);
  height: 40px;
  width: 100%;
}

.row.reward_main.active_reward .reward_first {
  background-color: var(--dark);
}

.row.reward_main.active_reward .reward_first p {
  color: var(--light);
}

.form_country .css-1s2u09g-control {
  border-radius: 20px;
}

.avatarlist {
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-type: none;
  text-align: center;
  margin-bottom: 2em;
}

.avatarlist::-webkit-scrollbar {
  visibility: unset;
}

.avatarlist .item img {
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid transparent;
  padding: 20px;
}

.item.activeAvatar {
  position: relative;
  border: 2px solid var(--primary);
  border-radius: 15px;
  transition: border-radius 0s;
}

/* .item.activeAvatar img {
    border: 2px solid var(--primary);
} */

.item.activeAvatar i {
  position: absolute;
  right: 5px;
  top: 5px;
  color: var(--primary);
}

a.coin_hover:hover {
  color: var(--dark) !important;
}

.rewards_tab div {
  cursor: pointer;
}

.rewards_tab div p {
  color: var(--light);
  text-align: center;
  font-size: 20px;
  font-weight: lighter;
  width: 90%;
}

.rewards_tab>div {
  border-right: 1px solid var(--primary);
}

html[dir="rtl"] .rewards_tab>div:first-child {
  border-right: none;
}

.rewards_tab div.active p {
  border-bottom: 3px solid var(--primary);
}

.reward_first_main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* margin-bottom: 60px; */
  position: relative;
  /* background-color: var(--light); */
  background: #fff;
  visibility: visible;
  transition: all 0.3s ease-in;
  overflow: auto;
  overflow-x: hidden;
}

.reward_second_main {
  background-color: #fff;
  border-radius: 35px;
  height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 25px; */
}

.reward_second_main .tab-content {
  min-height: 75%;
  max-height: 90%;
  overflow: auto;
}

.reward_second_main .tab-content::-webkit-scrollbar {
  display: none;
}

.reward_second_main .tab-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.reward_first_main {
  background-color: var(--dark);
}

.rewards_coin {
  text-align: center;
}

.rewards_coin .btn {
  width: auto;
}

.coin_reward_tabs .active {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
}

.coin_reward_tabs {
  position: relative;
  top: -15px;
  width: 70%;
  margin: 0 auto !important;
  border: 1px solid var(--dark);
}

.packs_first {
  background-color: var(--dark);
  border-radius: 10px;
  color: #fff;
  padding: 20px 0;
}

.packs_first img {
  width: 60%;
  margin-bottom: 10px;
}

.packs_first p {
  color: var(--light);
  margin-bottom: 0;
}

.packs_first p:first-child {
  font-size: 20px;
  font-weight: bold;
}

.packs_second {
  background-color: var(--secondary);
  color: var(--light);
  border-radius: 15px;
  padding: 4px 0;
  position: relative;
  top: -17px;
  /* width: 100px; */
  margin: 0 auto;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
}

.bundles_second {
  width: 90%;
  background-color: var(--secondary);
  color: var(--light);
  border-radius: 15px;
  padding: 4px 0;
  position: relative;
  margin: 5px auto;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
}

.bg-design {
  height: 65%;
  background-image: url("../../assets/images/inside-curved-bg-bundle.png");
  position: absolute;
  /* width: 200px; */
  /* top: 148px; */
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 18px;
  border-radius: 0 0 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
}

.bundles_first {
  position: relative;
}

.coin_img_avatar {
  width: 20px;
}

.avatar_cat_main .coin_img {
  width: 20px !important;
  height: 20px;
}

.bg-design-avatars {
  height: 65%;
  background-image: url("../../assets/images/inside-curved-bg-bundle.png");
  position: absolute;
  /* width: 170px; */
  /* top: 130px; */
  bottom: 10px;
  left: 0;
  right: 0;
  /* margin: 0 18px; */
  border-radius: 0 0 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
}

.avtara_cat_img {
  height: 35px;
  width: 35px;
  margin-right: 1rem;
}

html[dir="rtl"] .avtara_cat_img {
  margin-left: 1rem;
}

.avatar_cat_name {
  padding-left: 15px;
  border-left: 2px solid var(--dark);
  line-height: 30px;
}

html[dir="rtl"] .avatar_cat_name {
  border-right: 2px solid var(--dark);
  border-left: none;
  padding-right: 15px;

}

.reward1_first {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reward1_gradient {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to bottom,
      rgba(82, 82, 82, 0) 0%,
      #525252 100%);
}

.reward1_second {
  position: absolute;
  margin-top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.scrim-card-padding-top {
  padding-top: 0.5rem;
}

.scrim-card-padding-bottom {
  padding-bottom: 0.5rem;
}

.scrim-summary-title {
  color: var(--dark);
  font-weight: 700;
}

.redeem_click {
  background-color: var(--dark);
  width: 100%;
  border-radius: 0 0px 23px 23px;
  text-align: center;
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
}

h4.reward_cat_name:after {
  /* content: ""; */
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid var(--dark);
}

.reward_cat_name {
  position: relative;
  width: 80%;
}

html[dir="rtl"] .reward_cat_name {
  text-align: right;
}

.reward_cat_name span {
  background-color: white;
  padding-right: 10px;
}

html[dir="rtl"] .reward_cat_name span {
  padding-right: 0;
}

.list-group-item-action {
  cursor: pointer;
}

.reward_second_main .list-group-item-action.active:hover {
  color: var(--light) !important;
}

.inner-wrap #popup_show:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  background: #070b28;
  opacity: 0.75;
}

#account_main {
  padding-top: 0;
}

.mycontest_main .contest_details_foot {
  width: 90%;
  margin: 0 auto;
  position: relative;
  bottom: -15px;
  border-radius: 5px 5px 15px 15px;
}

.scrimcard {
  bottom: -22px !important;
}

.mycontest_main .status_contest {
  width: 180px;
  border-radius: 25px;
  text-align: center;
  position: relative;
  top: 12px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 auto;
}

#my_contest_main_inner .list-group.main_tabs {
  margin-bottom: 0 !important;
  border-radius: unset;
  background: unset;
  border: unset;
  width: 80%;
  margin: 0 auto;
}

#my_contest_main_inner .main_tabs .list-group-item.active {
  color: var(--light);
  border-bottom: 3px solid var(--primary);
  border-radius: unset;
}

#my_contest_main_inner .main_tabs .list-group-item {
  background-color: unset;
  color: var(--light);
  font-size: 16px;
  font-weight: 600;
}

/* Coin Store - How To Redeem Starts */
.coin_store_htr_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.coin_store_htr_popup {
  width: 100%;
  /* height: 50%; */
  background-color: white;
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.coin_store_htr_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: var(--light); */
  border-radius: 35px 35px 0 0;
}

.coin_store_htr_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
  color: var(--dark);
}

.coin_store_htr_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.coin_store_htr_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* background-color: var(--color_white); */
}

.coin_store_htr_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  /* background-color: var(--color_white); */
  overflow: auto;
  /* margin-bottom: 60px; */
  /* padding-bottom: 60px; */
}

.coin_store_htr_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.coin_store_htr_text {
  width: 100%;
  color: black;
  /* display: flex; */
  /* justify-content: start; */
  font-size: 16px;
  /* padding: 15px 15px 15px 0; */
}

/* Coin Store - How To Redeem Ends */

.my_rewads_main {
  width: 90%;
  margin: 15px auto;
  border-radius: 15px;
  padding: 5px;
}

html[dir="rtl"] .my_rewads_main .coupe_img {
  border-radius: 0 10px 0 10px !important;
}

.my_rewads_main .coupe_img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  /* min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px; */
  /* margin-left: 10px; */
  border-radius: 10px 0 10px 0;
  border: 1px solid #fff;
}

.my_rewads_main .reward_coin_second {
  width: 100%;
  margin: 0 auto;
}

.my_rewads_main .coin_img_myreward {
  width: 20px;
  height: 20px;
}

.my_rewards_pending_failed_success_status {
  background-color: var(--light);
  color: var(--dark);
  padding: 7px;
  border-radius: 0 10px 0 10px;
}

.terms_main {
  overflow-y: auto;
}

.leader_background {
  border-radius: 70px;
  /* z-index: 999999; */
  position: relative;
  top: 3em;
}

.searchbar {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbar input {
  /* border: unset; */
  border: none;
  border-radius: 20px;
  width: 80%;
  height: 35px;
  position: relative;
  top: -20px;
  padding: 5px 12px;
}

.searchbar .i_div {
  width: fit-content;
  position: relative;
  top: -20px;
  /* background: white; */
  padding: 2px;
  border: none;
  border-radius: 20px;
  left: -2.3rem;
}

html[dir="rtl"] .searchbar .i_div {
  left: 2.3rem;
}

.searchbar i {
  /* position: absolute; */
  /* right: 50px; */
  /* top: -19px; */
  background: var(--secondary);
  color: var(--light);
  padding: 8px;
  font-size: 15px;
  border-radius: 15px;
  cursor: pointer;
}

html[dir="rtl"] .searchbar i {
  transform: rotate(90deg);
}

/* .header_main_leader { */
/* position: relative; */
/* top: 2em; */
/* margin-top: 2em; */
/* } */

.header_main_leader .list-group.main-tab .list-group-item.active {
  color: var(--dark) !important;
}

.no_records {
  height: 400px;
  text-align: center;
  width: 100%;
  position: relative;
  top: 3em;
}

.player_list .current_user:hover {
  background: unset;
}

#popup_show_redeem {
  overflow: hidden;
  position: unset;
}

#popup_show_redeem:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  background: #070b28;
  opacity: 0.75;
}

.reward_first_main .redeem_popup {
  position: fixed;
  max-width: 475px;
}

.pointsname_gamerji {
  width: 75%;
}

html[dir="rtl"] .pointsname_gamerji {
  text-align: right;
}

.points_gamerji {
  width: 25%;
  text-align: right;
  color: var(--secondary);
  font-weight: bold;
}

html[dir="rtl"] .points_gamerji {
  text-align: left;
}

.gamerji_points_tab_main {
  display: flex;
  background-color: var(--secondary);
  margin-bottom: 20px;
  align-items: center;
  border-radius: 35px;
  width: 100%;
  /* flex: 1; */
  font-weight: 600;
}

.gamerji_points_tab_main div {
  color: var(--light);
  margin-bottom: 0px;
  cursor: pointer;
  /* font-size: 13px; */
  padding: 10px 0;
  text-align: center;
  border-radius: 35px;
  /* height: 45px; */
}

.gamerji_points_tab_main .active {
  background-color: var(--dark);
}

.error p {
  color: var(--secondary);
  font-weight: bold;
  text-align: center;
}

.points_tabs .accordion-header {
  cursor: unset;
}

.join_code,
.join_code .accordion-header[aria-expanded="true"],
.join_code .accordion-header {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
  color: var(--dark);
  border-radius: 50px;
}

.join_code {
  position: relative;
}

.join_code input {
  width: 100%;
  background: #fff;
  height: 40px;
  border: unset;
  border-radius: 10px;
  padding-left: 10px;
  /* text-transform: uppercase; */
  font-size: 14px;
}

html[dir="rtl"] .join_code input {
  padding-right: 10px;
}

.join_code button {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #000;
  border: unset;
  border-radius: 6px;
  color: #fff;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  min-width: 5rem;
  max-width: 8rem;
  height: 1.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html[dir="rtl"] .join_code button {
  left: 10px;
  right: calc(100% - 5.5rem);
}

.cc_rc_contest_list_form {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 2px solid #d5d7e3;
  border-radius: 15px;
  color: #70717a;
  padding: 20px;
}

.cc_rc_contest_list_col {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.cc_rc_contest_list_input {
  width: 100%;
  height: fit-content;
  padding: 7px 20px;
  border-bottom: 1px solid var(--color_grey);
  font-family: "Proxima Nova";
  font-size: 17px;
  transition: all 0.5s ease;
  font-weight: 600;
  display: flex;
}

.tc_popup_bg {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.form-group label {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  line-height: 120%;
}

.cc_rc_contest_list_input label.form-check-label {
  font-family: "Proxima Nova";
  font-size: 0.9rem;
  transition: all 0.5s ease;
  font-weight: 600;
  margin-left: 10px;
  height: 18px;
}

html[dir="rtl"] .cc_rc_contest_list_input label.form-check-label {
  margin-right: 30px;
  margin-left: 0;
}

.cc_rc_contest_list_divider {
  width: 100%;
  height: 1px;
  color: var(--color_grey);
}

textarea.text_decribe {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #d5d7e3;
  padding: 10px;
}

.upload_ss {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.upload_ss div div {
  background-color: #fff;
  margin: 0 auto;
  width: 60px;
  height: 50px;
  border-radius: 40px;
  position: relative;
  top: -13px;
}

.upload_ss img {
  background-color: #fff;
  padding-top: 12px;
}

.incoming_chats p {
  background-color: #ccc;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 3px;
  max-width: 80%;
}

.incoming_chats span {
  font-size: 12px;
}

.sent_chats h6 {
  font-size: 12px;
  text-align: right;
}

.sent_chats p {
  background-color: #000;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 3px;
  color: #fff;
  float: right;
  max-width: 80%;
}

.enter_chat div {
  background-color: var(--dark);
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: 5px;
}

html[dir="rtl"] .enter_chat div {
  right: 90%;
  left: 0;

}

.enter_chat img {
  width: 15px;
  position: relative;
  right: -11px;
  top: 6px;
}

.enter_chat input {
  width: 100%;
  border: unset;
  border-top: 1px solid #000;
  height: 50px;
}

.enter_chat {
  position: relative;
  bottom: 0;
  width: 100%;
}

.enter_chat div {
  cursor: pointer;
}

.complaint_list {
  /* padding-bottom: 50px; */
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .enter_chat {
    width: 94%;
  }

  .account_first {
    height: 80%;
  }

  .account_first .coin_total p {
    font-size: 15px;
  }

  .coin_total span {
    font-size: 12px;
  }

  .account_main {
    overflow: auto;
  }

  .coin_val {
    font-size: 18px;
  }

  .coin_img_medium {
    height: 24px;
  }

  .cash_box {
    padding: 15px 10px 15px 10px;
    margin-bottom: 25px;
  }

  .cash_box div {
    padding: 0;
  }
}

.yellow-signup-box {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px 10px 35px 35px;
  border: 1px solid var(--dark) !important;
}

.yellow-signup-box p {
  font-size: 18px;
  font-weight: 500;
}

.signup_main_items .owl-theme .owl-nav {
  position: absolute;
  top: -20px;
}

.signup_main_items .owl-theme .owl-nav .owl-prev {
  margin-left: 0 !important;
}

.signup_main_items .owl-theme .owl-nav .owl-next {
  left: 240px;
}

.signup_main_items .owl-theme .owl-stage-outer .owl-stage {
  padding: 0 !important;
}

.signup-main {
  height: 100vh;
  /*  position: relative; */
  overflow: hidden;
}

.signup-main .body {
  height: 100%;
  overflow: auto;
}

.signup-submit {
  width: 90%;
  margin: 0 auto;
  margin-top: 55px;
  margin-bottom: 20px;
}

.activeAvatarOne {
  position: relative;
  border: 1px solid var(--dark);
  /* padding: 1px; */
  /* padding: 10px; */
  /* margin:0px 20px !important; */
  border-radius: 15px;
  transition: border-radius 0s;
}

.activeAvatarOne .fa.fa-check-circle {
  position: absolute;
  right: 10px;
}

/* DobNstate Starts */
.dobns_main_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.dobns_popup {
  width: 100%;
  /* height: 50%; */
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

@media screen and (max-height: 670px) {
  .dobns_popup {
    height: 100%;
  }
}

.dobns_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* background-color: var(--color_yellow); */
  border-radius: 35px 35px 0 0;
}

.dobns_header_text {
  font-size: 20px;
  font-weight: 600;
  margin-left: 35px;
}

.dobns_cancel_icon {
  width: 30px;
  cursor: pointer;
}

.dobns_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
}

.dobns_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--color_white);
  overflow: auto;
  margin-bottom: 60px;
  /* padding-bottom: 60px; */
}

.dobns_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.dobns_basic_profile_inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.doneBtn {
  width: 100%;
  background-color: #fff;
  text-align: center;

  padding-bottom: 1rem;
}

.doneBtn button {
  padding: 0.5rem 3rem;
  background-color: var(--dark);
  color: var(--light);
  border: none;
  outline: none;
  border-radius: 20px;
}

.dobns_text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 5px 15px 5px 0;
}

.dobns_input_component {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 20px;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
  position: relative;
  /* margin-bottom: 20px; */
}

html[dir="rtl"] .dobns_input {
  text-align: right;
}

.dobns_input {
  width: 100%;
  display: flex;
  font-size: 18px;
  /* padding: 10px 15px 10px 20px; */
  outline: none;
  border: none;
  outline: none;
  background-color: transparent;
}

.dobns_input::placeholder {
  font-size: 1rem;
}

.date-popup-button {
  border: none;
  background-color: transparent;
}

.dobns_icon {
  display: flex;
  margin-right: -5px;
}

.css-1okebmr-indicatorSeparator {
  background-color: white !important;
}

.css-tj5bde-Svg {
  color: rgba(82, 81, 81, 0.541);
}

.dobns_error_div {
  width: 100%;
  height: 20px;
  font-size: 16px;
  color: var(--secondary);
  margin-bottom: 5px;
  /* display: none; */
}

.dobns_state_div {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.5);
}

.dobns_state_popup {
  width: 100%;
  height: 50%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  border-radius: 35px 35px 0 0;
}

.dobns_state_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--color_white);
}

.dobns_state_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  padding: 25px;
  background-color: var(--color_white);
  overflow: auto;
  margin-bottom: 60px;
  padding-bottom: 60px;
}

.dobns_state_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.dobns_state_list_text {
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid #d3d3d3;
  margin: 10px 0;
  cursor: pointer;
}

.dobns_state_list_text:hover {
  background-color: #d3d3d3;
  border-radius: 50px;
}

.dobns_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px 10px 20px;
  border: none;
  border-radius: 50px;
  background-color: var(--color_black);
  color: var(--color_white);
  outline: none;
  margin-top: 25px;
  text-transform: uppercase;
}

.dobns_button:hover {
  background-color: var(--color_red);
}

.dobns_button:disabled {
  background-color: #070b28ba;
}

/* DobNstate Ends */

/* Squad Registration */
.squad_registration_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
}

.squad_registration_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.squad_registration_caption_player_row {
  width: 100%;
  margin: 10px auto;
}

.marquee-div {
  font-size: 1rem;
  /* font-weight: 700; */
  height: 25px;
  overflow: hidden;
  position: relative;
}

.marquee-text {
  display: block;
  width: 200%;

  position: absolute;
  overflow: hidden;

  animation: marquee 5s linear infinite;
  float: left;
}

html[dir="rtl"] .marquee-text {
  float: right;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

/*  */
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  visibility: hidden !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

@media (max-width: 1366px) and (max-height: 768px) {
  .d-flex.justify-content-centerr {
    /*top: 57% !important;
    left: 28% !important;
    */
    top: 51% !important;
    left: 17% !important;
    position: fixed !important;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
  }
}

.picker-modal-container {
  width: 100%;
  height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
}

.picker-modal {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;

  flex-basis: 475px;
  max-width: 475px;
  min-width: 475px;

  transform: translate(0, 100%);
  backface-visibility: hidden;
  transition: transform 0.3s;

  &.picker-modal-toggle {
    transform: translate(0, 0);
  }
}

.picker-container {
  background-color: #fff;
}

.ios-datepicker {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 0 24px;
  border-radius: 40px 40px 0 0;
  background-color: #fff;
}

.ios-datepicker-title {
  flex: 1 1;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  /* background-color: #fbf9fe; */
}

.dobns_select_date {
  background-color: white;
  border-radius: 40px 40px 0 0;
  padding: 2rem;
}

.ios-datepicker a {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.5s;
  text-decoration: none;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  color: #222;
  font-weight: 600;
}

.friendship {
  width: 100%;
  background-color: var(--secondary);
  position: relative;
  height: 50px;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  align-content: space-around;
  border-radius: 40px;
  border: none;
  outline: none;
}

.friendship div {
  font-size: 12px;
  width: 50px;
  height: 50px;
  background-color: var(--primary);
  border-radius: 50%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -50%;
  border: 2px solid #070b28;
  display: flex;
  justify-content: center;
  align-items: center;
}

.friendship img {
  object-fit: contain;
}

.friendship h5 {
  font-size: 14px;
  color: var(--light);
  font-weight: 700;
}

.detailsButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.friendsCode {
  width: 100%;
  border: 2px solid #d5d7e3;
  border-radius: 40px;
  height: 40px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.friendsCode input {
  outline: none;
  border: none;
}

.friendsCode button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.scrimDetail h3 .span {
  width: 35px;
  height: 35px;
  background-color: transparent !important;
  margin-top: 10px;
  cursor: pointer;
  padding-top: 10px !important;
  padding-right: 12px !important;
}

.upperCase {
  text-transform: uppercase;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.persDrop {
  flex-direction: column;
  width: 50%;
}

.MuiPaper-root {
  width: 300px;
  position: absolute;
  right: -37px;
}

@media screen and (max-width: 412px) {
  .MuiPaper-root {
    /* background-color: green; */
    position: absolute;
    left: 0;
  }

  .popMobile {
    bottom: 48%;
    min-width: 100%;
  }
}

.ad-main {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px 20px;

  /* height: 100%; */
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.ad-main::-webkit-scrollbar {
  display: none;
}

.adSection {

  width: 100%;
  /* min-height: 180px; */
  border: 1px solid #70707030;
  border-radius: 5px;
  margin-bottom: 10px;
}

.adSection .layers {
  width: 100%;


}

.firstLayer {
  width: 100%;
  height: 65px;
  background-color: #ddedfd;
  padding: 10px 10px 0 10px;
  justify-content: space-around;
  position: relative;
}

.firstLayer .ad {
  width: 30px;
  height: 10px;
  background-color: var(--primary);
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: -10%;
  text-indent: 0;
}

.image {
  /* width: 48px;
   height: 48px;
   color: red; */
  min-width: 10%;
  height: 100%;

}

.image img {
  width: 48px;
  height: 48px;
}

.titles {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.secondlayer {
  height: 30px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.earnSteps {
  width: 100%;
  min-height: 50px;
  /* margin-top: 10px; */
  padding: 5px 10px;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0s ease 0s;


}

.earnSteps p {
  /* line-height: 1px; */
  margin-bottom: 0;
}

.secondlayer .steps {
  width: 100%;
  height: 100px;
  background-color: red;
}

.thirdLayer {
  height: 44px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  display: flex;
}

.thirdLayer button {
  width: 50%;
  height: 100%;
  border: none;
  padding: 10px 10px;
  display: flex;
}

.fg_category_body {
  padding: 0 !important;
}

.reward_cat_main .owl-carousel {
  width: 475px !important;
}

.image-size {
  width: 30px;
  height: 30px;
}

/*  Subscription css */
.subImage {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/backgroundSubscription.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  overflow-y: auto;
}

.ps_header {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ps_section {
  width: 100%;
  height: 500px;
  background-color: #1c1c1c;
  /* overflow-y: hidden; */
  border-radius: 10px;
}

.ps_footer {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ps_logo {
  width: 120px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps_logo img {
  width: 90px;
  height: 90px;
}

.ps_name {

  color: #fff;
  font-size: 32px;
}

.ps_buttonGroup {
  width: 90%;
  height: 40px;
  background: #1c1c1c;
  border-radius: 50px;
  margin: 10px;

}

.ps_buttonGroup .active {
  background: #C5A260;
}

.ps_buttonGroup button {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  color: #fff;
  padding: 5px 0;
  transition: all 0.4s ease-in-out;
}

.ps_button {
  width: 90%;
  height: 46px;
  border-radius: 20px;
}

.ps_button button {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #CFB279, #F1DCB1, #B39559);
  outline: none;
  border-radius: 20px;
  border: none;

}

.ps_heading {
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

}

.ps_heading h2 {
  font-size: 16px;
  margin: 0 10px;
}

.ps_benefits {
  width: 100%;
  height: calc(100% - 100px);

  overflow-y: scroll;
}

.ps_benefits {
  /* -ms-overflow-style: none; */
  /* Internet Explorer 10+ */
  /* scrollbar-width: 4px; */
  /* Firefox */
}

.ps_benefits::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
  display: block;
  width: 4px;

}

.ps_benefits::-webkit-scrollbar-track {
  /* background: green */
}

/* Handle */
.ps_benefits::-webkit-scrollbar-thumb {
  background: #B39559;
  border-radius: 2px;
}

.subImage::-webkit-scrollbar {
  /* display: none; */
  /* Safari and Chrome */
  display: block;
  width: 4px;

}

.subImage::-webkit-scrollbar-track {
  /* background: green */
}

/* Handle */
.subImage::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2px;
}

.ps_rect {
  width: 0;
  height: 0;
  border-top: 1.5px solid transparent;
  border-bottom: 1.5px solid transparent;
  border-radius: 50px;
  background: linear-gradient(to right, #CFB279, #F1DCB1, #B39559);
}

.ps_rect1 {
  border-right: 100px solid #B39559;
}

.ps_rect2 {
  border-left: 100px solid #B39559;
}

.ps_benefits_text {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
}

.ps_benefits_text .layer1 {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps_benefits_text .layer1 img {
  width: 30px;
  height: 30px;
}

.ps_benefits_text .layer2 {
  width: 80%;
  height: 100%;

}

.ps_benefits_text .layer2 h2 {
  font-size: 14px;
  color: #fff;
  font-weight: medium;
  margin: 0;
}

.ps_benefits_text .layer2 p {
  font-size: 12px;
  color: #9a9a9a;
  font-weight: normal;
  margin: 0;
  line-height: 1.1;
}

.renew_layer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;

}

.renew_layer button {
  outline: none;
  width: 87px;
  height: 30px;
  background: linear-gradient(to right, #CFB279, #F1DCB1, #B39559);
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
}

.ps_renew {
  width: 100%;
  /* height: 71px; */
  margin-bottom: 20px;

  /* border: 2px solid #BDA77E; */
  border-radius: 10px;
}

.ps_benefits_text .layer {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  /* padding-right: 10px; */
  align-items: center;
}

.ps_benefits_text .layer h2 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.ps_benefits_text .layer button {
  width: 100px;
  height: 30px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #c5a260;
  border-radius: 4px;
  font-size: 14px;
}

.ps_badge {
  width: 46px;
  height: 46px;
  background-color: #c5a260;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.premium_active {
  background-color: #1c1c1c !important;
  color: #fff !important;
}

.premium_active input[type="date"]::-webkit-inner-spin-button,
.premium_active input[type="date"]::-webkit-calendar-picker-indicator {
  /* filter: invert(1); */
}

.premium_active_btn {
  background: linear-gradient(to right, #CFB279, #F1DCB1, #B39559) !important;
}

.ps_coin_saved {
  min-width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid #C5A260;
  font-weight: 400;
  margin: 0 auto 20px auto;
  padding: 7px 12px;
  border-radius: 4px;
}

.ps_coin_saved p {
  color: #fff !important;
  font-size: 16px;
}

.ps_info {
  width: 90%;
  height: 71px;
  border: 2px solid #C5A260;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 15px;
  border-radius: 10px;
}

.ps_info_logo {
  width: 18%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps_info_logo img {
  width: 38px;
  height: 31px;
}

.ps_info_detail {
  width: 80%;
  padding: 5px;
  text-align: left;
  font-size: 14px;
  line-height: 1.1;
}

.border_white {
  border-bottom: 1px solid #707070 !important;
}

.renew_font {
  font-size: 14px;
}

.ps_popup_p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  width: 80%;
  margin: 0 auto;
}

.ps_popup_p p {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  margin: 0;
}

.ps_popup_main {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.ps_popup_main .layer2 {
  display: flex;
  flex-direction: column;
}

.ps_summary {
  width: 100%;
  height: 33px;
  text-align: center;
  color: #c5a260;
  padding: 0 12px;
  margin-bottom: 10px;
}

.ps_summary p {
  color: #C5A260;
  font-size: 17px;
}

.ps_summary .link {
  color: #c5a260;
  text-decoration: underline;

}

.coin_color {
  background-color: #c5a260;
}

html[dir="rtl"] .ps_player_front {
  right: 9rem !important;
}

.ps_player_front {
  position: absolute;
  top: 15%;
  right: 20%;
}

html[dir="rtl"] .ps_player {
  left: 20% !important;
  right: 0 !important;
}

.ps_player {
  position: absolute;
  top: 15%;
  right: 20%;
}

.ps_profile_icon {
  /* display: flex; */

}

.ps_profile_img {
  width: 68px;
  height: 60px;
  position: absolute;
  right: 20px;
  top: 3%;

}

.ps_avatar_op {
  position: absolute;
  background: linear-gradient(to bottom, #c9a86a, #f1dcb1, #b39559);
  width: 100%;
  height: 98%;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  border: none;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0.5rem;
}

.ps_avatar_op img {
  opacity: 1 !important;
}

.ps_avatar_img {
  position: absolute;
  width: 35px;
  height: 30px;
  right: 0;
  top: 5%;
}

.ps_avatar_icon_img {
  width: 200px;
  max-width: 200px;
}

.ps_customer_input {
  /* display: flex;
  flex-direction: column; */
  width: 80%;
}

.ps_customer_input input {
  width: 100%;
  border: 1px solid #DEDFE9;
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 10px;
  padding-left: 50px;
}

html[dir="rtl"] .ps_customer_input input {
  padding-right: 50px;
  padding-left: 10px;
}

.ps_currency {
  max-width: 18px !important;
  height: 20px;
  margin: 0 8px;
}

.shadow {
  filter: drop-shadow(0 3px 6px #c5a26033);
}

.ps_whiteBg {
  background-color: #fff;
}

.balance_img {

  margin-top: 0;
  height: 23px;
  width: 23px;
  margin-right: 8px;
}

.fontSize16 {
  font-size: 1.5rem;
}

.searchCollege {
  width: 100%;
  height: 42px;
  display: flex;
  background-color: red;
  border-radius: 46px;
  position: relative;
  border: 1px solid var(--dark);
  font-size: 1rem;
  position: relative;

}

.searchCollege input {
  width: 100%;
  outline: none;
  border-radius: 46px;
  padding: 5px 10px;
  border: none;

}

.searchCollege button {
  width: 100px;
  background-color: var(--dark);
  color: #fff;
  outline: none;
  padding: 3px 8px;
  border: none;
  border-radius: 46px;
  position: absolute;
  right: 0;
  height: 100%;
}

html[dir="rtl"] .searchCollege button {
  right: 76%;
  /* left: 0 !important; */
}


.ps_radio {
  width: 16px !important;
  height: 16px !important;
  top: 12px !important;
}

.ps_radio:after {
  width: 10px !important;
  height: 10px !important;
  background: #C5A260 !important;
}

.preminumUserProfile {
  /* background-image: url(../images/GoldPremium.png); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  width: 18px;
  height: 18px;
}

.verifiedUserIcon {
  height: 12px;
}

.r-40 {
  right: 35%;
}

.r-10 {
  right: 10%;
}

html[dir="rtl"] .mCS_img_loaded {
  transform: rotate(180deg);
}

.borderLeft {
  border-left: 1px solid #707070;
}

html[dir="rtl"] .borderLeft {
  border-right: 1px solid #707070;
  border-left: none;
}

.userNameIcon {
  width: 2.5rem;
  margin-right: 10px;
}

html[dir="rtl"] .userNameIcon {
  margin-left: 10px;
}

.flag-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.75rem;
}

html[dir="rtl"] .flag-icon {
  margin-right: 12px;
  margin-left: 0;
}

.tournament-username {
  width: 2.5rem;
  margin-right: 0.51rem;
}

html[dir="rtl"] .tournament-username {
  margin-left: 0.51rem;
  text-align: right;
}

html[dir="rtl"] .tournament-text {
  text-align: right;
}

html[dir="rtl"] .arrow-deg {
  transform: rotate(180deg);
}

.subs-back {
  position: absolute;
  top: 2%;
  left: 5%;
}

html[dir="rtl"] .subs-back {
  left: 90%;
}

html[dir="rtl"] .ps_benefits_text .layer2 {
  text-align: right;
}

.crm-phoneNo {
  position: absolute;
  left: 0;
  padding: 0 1rem;
  color: #70717A;
  font-weight: bold;

  top: 49%;

}

html[dir="rtl"] .crm-phoneNo {
  left: 85%;
  margin-right: 1rem;
}

.crm-no {
  padding-left: 3.5rem !important;
}

html[dir="rtl"] .crm-no {
  padding-right: 3.5rem !important;
}

.rewards-diamond {
  padding: 10px 40px;
  width: 190px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: 1px solid rgb(255, 255, 255);
}

html[dir="rtl"] .rewards-diamond {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-right: -1.3rem;
}

.rewards-coin {
  padding: 10px 40px;
  width: 190px;
  margin: auto -5px auto auto;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 1px solid rgb(255, 255, 255);
}

html[dir="rtl"] .rewards-coin {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin: auto auto auto -1rem;

}

.icon-24 {
  margin-right: 10px;
  width: 24px;
  height: 24px;

}

html[dir="rtl"] .icon-24 {
  margin-left: 10px;

}

html[dir="rtl"] .owl-carousel .owl-item {
  float: right !important;
}

html[dir="rtl"] .owl-carousel .owl-stage-outer {
  direction: ltr !important;
  padding: 0 30px;
}

.profile-ff {
  border-radius: 0px 20px 20px 0px;
  border-left: none;
  background-color: var(--dark);
  color: rgb(255, 255, 255);
}

html[dir="rtl"] .profile-ff {
  border-radius: 20px 0 0 20px;

}

.profile-ml-20 {
  margin-left: 20px;
}

html[dir="rtl"] .profile-ml-20 {
  margin-right: 20px;
}

html[dir="rtl"] .text-align-right {
  text-align: right;
}

html[dir="rtl"] .side-main {
  flex-direction: row-reverse;
}

.header-wallet-coins {
  margin-left: 3px;
  color: rgb(255, 255, 255);
  align-self: center;
  display: inline-block;
  max-width: 100%;
}

html[dir="rtl"] .header-wallet-coins {
  margin-right: 3px;
}

.leaderboard-premium {
  width: 7rem;
  margin-right: 0;
  justify-content: space-between;
}

.crm-label {
  position: absolute;
  margin-left: 15px;
  color: rgb(112, 113, 122);
  font-weight: bold;
  top: 12%;
}

html[dir="rtl"] .crm-label {
  margin-right: 15px;
}

.mb-07 {
  margin-bottom: 0.7rem !important;
}

html[dir="rtl"] .text-left {
  text-align: right !important;
}

html[dir="rtl"] .text-right {
  text-align: left !important;
}

.divider-white {
  border-right: 1px solid #fff;
}

html[dir="rtl"] .divider-white {
  border-left: 1px solid #fff !important;
  border-right: none;
}

html[dir="rtl"] .ml-2 {
  margin-left: 0.5rem !important;
}

html[dir="rtl"] .mr-2 {
  margin-right: 0.5rem !important;
}

.tick-icon {
  width: 20px;
  height: 25px;
  margin-left: 5px;
}

html[dir="rtl"] .tick-icon {
  margin-right: 7px;
}

html[dir="rtl"] .pl-4,
.px-4 {
  padding-right: 1.5rem !important;
}


html[dir="rtl"] .reward_first_main .redeem_popup {
  right: 90.5rem !important;

}

html[dir="rtl"] .redeem_popup .close-popup {
  left: -25rem;
}

html[dir="rtl"] .tournament_items .owl-carousel .owl-stage-outer {
  direction: rtl !important;
  padding: 0 0;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

html[dir="rtl"] .mtr_collapse_box_col_text {
  text-align: right;
  width: 30% !important;
}

/* auction styling */
.action-list {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  background: #FFF;
  /* height: 11rem; */
  margin: 0 20px;
}

.upcoming-auction {
  display: flex;
  min-width: 85px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  color: var(--white);
}

.action-list first {
  height: 15%;
}

.action-list second {
  height: 70%;
}

.bg-light-white {
  background-color: var(--light-white);
}

.bg-upcoming {
  background-color: var(--upcoming);
}

.bg-on-going {
  background-color: var(--on-going);
}

.up-border {
  border-top: 1px solid var(--border-color);
}

.bg-border-color {
  background-color: var(--btn-grey) !important;
  border-radius: 0px 8px !important;
  color: var(--white) !important;
  width: 30px !important;
  text-align: center;
}

.auction-create-private {

  height: 42px;
  color: var(--white);
  border-radius: 4px;
  background: var(--on-going);
}

.auction-join_code {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--on-going);
}

.auction-accordion .accordion-header {
  display: flex;
  align-items: center;
  /* box-shadow: 0 3px 6px 0 rgba(7, 11, 40, 0.1);
  border: 1px solid #d5d7e3; */
  /* border-radius: 50px; */
  padding: 10px 22px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  /* background: #fff; */
  position: relative;
  z-index: 2;
  transition: all 0.5s;
}

.auction-accordion .accordion {
  border: 1px solid var(--on-going);
}

.auction-accordion .accordion-header[aria-expanded="true"] {
  border-color: var(--light-white);
  background: var(--light-white);
  color: var(--on-going);
}

.auction-accordion .collapsed img {
  max-width: 26px;
  margin-right: 12px;
  max-height: 26px;
  transform: rotate(-90deg) !important;
}

.auction-accordion .accordion-header img {
  max-width: 26px;
  margin-right: 12px;
  max-height: 26px;
  transform: rotate(90deg);
}

html[dir="rtl"] .auction-accordion .accordion-header img {
  margin-left: 12px;
  margin-right: 0;

}

.auction-accordion .accordion-header b {
  font-size: 16px;
  margin-right: 12px;
  min-width: 30px;
  line-height: 120%;
}

.auction-accordion .accordion-header[aria-expanded="true"] b {
  color: #fff !important;
}

.auction-accordion .accordion-header .icon {
  margin-left: auto;
  width: 18px;
}

html[dir="rtl"] .auction-accordion .accordion-header .icon {
  margin-right: auto;
  margin-left: 10px;


}

.auction-accordion .accordion-header[aria-expanded="true"] .icon {
  transform: rotate(-90deg);
}

html[dir="rtl"] .auction-accordion .accordion-header[aria-expanded="true"] .icon {
  transform: rotate(90deg);
}

.auction_join_code,
.auction_join_code .accordion-header[aria-expanded="true"],
.auction_join_code .accordion-header {
  background-color: var(--light-white);
  border-color: var(--primary);
  box-shadow: none;
  color: var(--on-going);
  /* border-radius: 50px; */
  display: flex;
  justify-content: center;
}

.auction_join_code {
  position: relative;
  height: 60px;
}

.auction_join_code input {
  width: 90%;
  background: var(--white);
  height: 50px;
  border: unset;
  border-radius: 10px;
  padding-left: 10px;
  /* text-transform: uppercase; */
  font-size: 14px;
  margin: 0 auto;
}

.auction_join_code button {
  position: absolute;
  right: 1rem;
  top: 5px;
  background-color: var(--on-going);
  border: unset;
  border-radius: 6px;
  color: #fff;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  min-width: 7rem;
  max-width: 8rem;
  height: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-leagues {
  height: 10rem;
  border-radius: 8px;
  background: linear-gradient(99deg, #082240 0%, rgba(8, 34, 64, 0.70) 100%);
}

.view-leagues .first {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-leagues .second {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  padding: 0 12px;
}

.auction-body {
  height: calc(100% - 4rem) !important;
  border-radius: 45px !important;
}

.auction-foooter {
  background-color: var(--dark);
  height: 4rem;
  padding: 10px 0;
}

.auction-box {
  background-color: var(--white) !important;
  color: var(--on-going);
  border: 1px solid var(--border-color);
  height: 16rem;
  border-radius: 8px !important;
  margin: 0 0 1rem !important;
}

.auction-contest-foot {
  width: calc(100% + 20px);
  background-color: var(--white) !important;
  border-top: 1px solid var(--border-color);
  top: 3%;
  height: 3rem;
  padding-top: 0.5rem;
  margin: 0;

}

.auction-box .avalible_wrap {
  width: 100%;
  padding: 0 10px;
  height: 10px;
  /* margin-top: -15px; */
}

.auction-box .inner_row {
  height: 3.5rem;
  padding: 5px 0;
  display: flex;
}

.auction-box .inner_row .auction-date-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 32%;
  font-size: 0.75rem;
  padding: 0 5px;
  line-height: 120%;
  height: 40px;
  /* margin-top: 20px; */
}

.auction-box .avalible_wrap .inner {
  background-color: var(--border-color);
  box-shadow: none;
}

.auction-box .avalible_wrap .inner span {
  border-radius: 100px;
  background: linear-gradient(-270deg, #F92C2C 0%, rgba(255, 198, 9, 0.70) 99%);
}

.border-right-grey {
  border-right: 1px solid #858585 !important;
}

.text-on {
  color: var(--on-going);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.auction-create-private-form {
  margin: 0 20px;
}

.auction-create-private-form .MuiTextField-root {
  width: 100%;
}

.auction-create-private-form .MuiOutlinedInput-notchedOutline {
  border-color: var(--on-going);
}

.auction_upload {
  width: 6.2rem;
  height: 6.2rem;
  border-radius: 50%;
  background-color: #C4C4C4;
  filter: drop-shadow(0px 0px 4px rgba(55, 55, 55, 0.25));
  margin: 10px auto;

}

.auction_upload .upload_ss {
  background-color: transparent;
  /* height: 100%; */
  padding: 0;
}

.auction_upload .upload_ss div div {
  background-color: transparent;
  top: 30px;
}

.MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #000 !important;
}

.auction-create-radio {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.auction-create-radio .auction-info {
  margin-top: -5px;
}

.text-error {
  color: var(--secondary);
}

.auction_upload .upload_ss img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 0;
}

.auction-team {
  width: 100%;
  min-height: 5rem;
  max-height: 28rem;
  overflow-y: scroll;
}

.auction-team-name {
  background-color: var(--white);
  height: 60px;
}

.auction-btn-invite {
  height: 3.25rem;
  color: var(--white);
  position: absolute;
  bottom: 3%;
  /* background-color: var(--base-color); */
  margin-top: 25px;
}

.auction-btn-invite div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 45%;
}

.bg-btn-grey {
  background-color: var(--btn-grey);
}

.p-35 {
  padding: 25px 0 !important;
}

.auction-create-private:hover {
  color: var(--white);
}

.auction-timer {
  background-color: var(--white);
  height: 18rem;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auction-rules {
  height: 34rem;
  padding: 20px;
  background-color: var(--white);
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 8px;

}

.time {
  color: var(--time-color);
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5px;
}

.auction-timer p {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auction-timer div {
  width: 18rem;
  display: flex;

}

.auction-timer .minutes {

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.auction-nominate {
  height: 12rem;
  background-color: var(--white);
  padding: 20px 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auction-nominate P {
  color: #858585;
  text-align: center;

  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.auction-nominate button {
  color: var(--white);
  border-radius: 4px;
  margin: 10px auto;

}

.bid-first {
  height: 65px;
  border-radius: 30px 30px 0px 0px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
    background: linear-gradient(90deg, #0C2443 0%, #BCD9FF 51.4%, #2C435C 100%);
    background-size: contain;

  }

}

.bid-first .inner {
  display: flex;
  width: 100%;
  position: relative;
  padding: 20px 10px;
}

.bid-first .inner div {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
}

.border-right-black {
  border-right: 1.5px solid var(--on-going) !important;
}

.bid-remaining {

  height: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: var(--secondary);
    opacity: 0.2;
    background-size: contain;

  }
}

.bid-remaining span {
  position: relative;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bid-box {
  height: 19rem;



  /* padding: 20px; */
}

.bid-upper {
  height: 80%;
}

.bid-foot {
  height: 20%;
}

.bid-box-inner {
  height: 100%;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #D0D0D0;
  background-color: var(--white);
  padding: 15px;
}

.bid-upper .first {
  width: 100%;
  height: 100%;
}

.bid-upper .first img {
  width: 100%;
  height: 100%;
}

.bid-match {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.bid-match div {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
}

.match-text {
  color: #858585;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.25rem;
}

.bid-price {
  height: 10rem;
  width: 100%;
  padding: 0 20px;

}

.bid-price .first {
  width: 48%;
  border-radius: 8px;
  height: 100%;
  border: 1px solid #D0D0D0;
  background: var(--white);

}

.bid-base-price {
  height: 40px;
  border-radius: 7px 7px 0px 0px;
  background: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bid-btn {
  height: 50px;
  color: var(--white);
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--on-going);
  font-weight: 600;
  font-size: 1.2rem;
}

.h-80 {
  height: 80%;
}

.disabled {
  background-color: var(--btn-grey) !important;
}

.enter-buy-team {

  height: 50rem;
  overflow-y: scroll;
  margin: 20px;
  overflow-x: hidden;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.remaining-budget {
  width: 100%;
  height: 4.5rem;
  background-color: var(--white);
  display: flex;
  justify-content: space-around;
  padding: 5px;
}

.remaining-budget div {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 30px;
  padding: 0 10px;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.text-upcoming {
  color: var(--upcoming);
}

.enter-buy-team .first {
  height: 40px;
}

.enter-buy-team .second {
  background-color: var(--white);
  height: 60px;
  border-bottom: 1px solid var(--border-color);
}

.unsold-div {
  border-radius: 2px;
  background: rgba(249, 44, 44, 0.20);
  color: var(--secondary);
  display: flex;
  width: 85px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
}

.auction-remaining .MuiInput-underline:before {
  border-bottom: none !important;
}

.auction-remaining .MuiInput-underline::after {
  border-bottom: none !important;
}

.auction-remaining .MuiInputLabel-formControl {
  top: -20% !important;
  left: 10% !important;
  color: black !important;
}

.auction-remaining .MuiSelect-icon {
  top: -15% !important;
}

.auction-remaining .MuiSelect-select:focus {
  background-color: #fff !important;
  border-bottom: none !important;
}

.bg-on-going:hover {
  color: var(--white) !important;
}

.reauction {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 1.2rem;
}

.btn-nominate {
  width: 5rem;
  height: 30px;
  color: var(--white);
  padding: 0;
  /* border: 1px solid var(--on-going); */
  padding-right: 5px;
  border-radius: 4px;

}

.sound-setting {
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.sound-setting .MuiFormControlLabel-root {
  margin-right: -10px !important;
}

.settings-rules {
  height: 35rem;
  overflow-y: scroll;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 20px;
  padding: 10px;
  background-color: #fff;

}

.bg-blue {
  background-color: var(--bg-blue) !important;
}

.edit-filter .MuiFormControl-root {
  width: 35%;
}

.h-12 {
  height: 12rem;
}

.league_tab {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin-bottom: 40px;

}

.league_public_tab {
  display: flex;
  border-radius: 50px;
  flex-direction: row;
  position: absolute;
  width: 92%;
  border: none;
  background-color: var(--white);
  /* top: -10%; */
  margin-top: -20px;
}

.league_tab .list-group-item {
  background-color: transparent;
  color: #fff;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 600;

}

.league_tab .list-group-item.active {
  background-color: transparent;

  border-color: transparent;
  border-bottom: 2px solid var(--primary);
}

.league_public_tab .list-group-item {
  border-radius: 50px;
  border: none;
  font-size: 1.1rem;
  text-align: center;
  color: var(--on-going);
  margin-top: 0.5px;
}

.league_public_tab .list-group-item.active {
  background-color: var(--on-going);
  border-color: transparent;
  border-radius: 50px;
  color: var(--white);
  margin-top: 0.5px;

}

.league-upcoming {
  padding: 0;
  padding-top: 40px;
}

.bg-upcoming-blue {
  background-color: var(--upcoming-blue);
}

.bg-live-red {
  background-color: var(--live-red);
}

.faq-btn {
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 4px;
  background-color: #fff;
}

.faq-accordion .MuiPaper-root {
  width: 100%;
  left: 0;
}

.faq-accordion .MuiAccordionDetails-root {
  flex-direction: column;
}

.faq-accordion h6 {
  line-height: 1;
  font-size: 1.1rem;
  font-weight: 600;
}

.faq-accordion .MuiTypography-body1 {
  line-height: 1;
}

.faq-accordion .accordion {
  display: flex;
  flex-direction: column;
  font-family: "Sora", sans-serif;
  max-width: 991px;
  min-width: 320px;

}

.faq-accordion .accordion h1 {
  font-size: 32px;
  text-align: center;
}

.faq-accordion .accordion-item {
  margin-top: 16px;
  border-bottom: 1px solid var(--border-color);
  /* border-radius: 6px; */
  background: #ffffff;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
}

.faq-accordion .accordion-item .accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: center;
}

.faq-accordion .accordion-item .accordion-item-desc {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: #444;
  /* border-top: 1px dashed #ddd; */
  /* padding: 10px 20px 20px; */
  padding-bottom: 10px;
  box-sizing: border-box;
}

.faq-accordion .accordion-item input[type="checkbox"] {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.faq-accordion .accordion-item input[type="checkbox"]:checked~.accordion-item-desc {
  display: block;
}

.faq-accordion .accordion-item input[type="checkbox"]:checked~.accordion-item-title .icon:after {
  content: "-";
  font-size: 20px;
}

.faq-accordion .accordion-item input[type="checkbox"]~.accordion-item-title .icon:after {
  content: "+";
  font-size: 20px;
}

.faq-accordion .accordion-item:first-child {
  margin-top: 0;
}

.faq-accordion .accordion-item .icon {
  margin-left: 14px;
}

.text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mgT-8 {
  margin-top: 8rem;
}

.tooltip-arrow {
  content: "";
  background: #333;
}

.auction_list_box {
  height: 15rem;
  /* background-color: green; */
}

.he-25 {
  height: 30rem;
}

.auction-remaining .MuiSelect-select.MuiSelect-select {
  padding-left: 10px;
}

.border-dark {
  border: 1px solid var(--on-going)
}

.auction-date {
  height: 6rem;

}

.auction-date .dobns_input_component {
  border-radius: 8px;
  border: 1px solid var(--on-going);
  position: absolute;
  height: 56px;

}

.auction-date .dobns_input_component label {
  position: absolute;
  top: -10%;
  background: var(--base-color);
  height: 10px;
  font-size: 12px;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
}

.mgn-20 {
  margin: 0 1.25rem;
}

.font-18 {
  font-size: 18px;
}

html[lang="id"] .dobns_input::placeholder {
  font-size: 0.8rem !important;
}

.css-hz1bth-MuiDialog-container {
  justify-content: flex-start !important;
}

.linka {
  color: var(--dark);
}